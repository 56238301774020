export default {
  namespaced: true,
  state: {
    competition: {},
    competitions: [],
    points: [],
    participants: [],
    ranking: [],
    results: [],
    usersSelecetedView: [],
    usersSelectInteractive: [],
    usersSelectedInteractive: [],
    interactivePolicy: false,
    comments:[]
  },
  getters: {
    getCompetition(state) {
      return state.competition;
    },
    getCompetitions(state) {
      return state.competitions;
    },
    getPoints(state) {
      return state.points;
    },
    getParticipants(state) {
      return state.participants;
    },
    getRanking(state) {
      return state.ranking;
    },
    getResults(state) {
      return state.results;
    },
    getUsersSelecetedView(state) {
      return state.usersSelecetedView;
    },
    getUsersSelectInteractive(state) {
      return state.usersSelectInteractive;
    },
    getInteractivePolicy(state) {
      return state.interactivePolicy;
    },
    getUsersSelectedInteractive(state) {
      return state.usersSelectedInteractive;
    },
  },
  mutations: {
    setCompetition(state, payload) {
      state.competition = payload;
    },
    setCompetitions(state, payload) {
      state.competitions = payload;
    },
    setNewCompetitions(state, payload) {
      state.competition = payload;
    },
    setPoints(state, payload) {
      state.points = payload;
    },
    setComments(state, payload){
      state.comments = payload
    },
    setParticipants(state, payload) {
      state.participants = payload;
    },
    setRanking(state, payload) {
      state.ranking = payload;
    },
    setNewRanking(state, payload) {
      state.ranking.push(...payload);
    },
    setResults(state, payload) {
      state.results = payload;
    },
    setUsersSelecetedReset(state) {
      state.usersSelecetedView = [];
      state.usersSelectInteractive = [];
      state.usersSelectedInteractive = [];
    },
    setUsersSelecetedView(state, payload) {
      const { checkedAll, add, users, _id, areas } = payload;

      const addNewUsers = () => {
        const newUsers = users.filter((user) => !state.usersSelecetedView.some((existingUser) => existingUser._id === user._id));
        state.usersSelecetedView.push(...newUsers);
        state.usersSelectInteractive = state.usersSelecetedView;
      };

      const removeUsersByArea = () => {
        state.usersSelecetedView = state.usersSelecetedView.filter((user) => !areas.includes(user.area?._id || 1));
        state.usersSelectInteractive = state.usersSelecetedView;
      };

      const toggleUserSelection = () => {
        const existingUserIndex = state.usersSelecetedView.findIndex((existingUser) => existingUser._id === _id);
        if (existingUserIndex !== -1) {
          state.usersSelecetedView.splice(existingUserIndex, 1);
          state.usersSelectInteractive = state.usersSelecetedView;
        } else {
          state.usersSelecetedView.push(payload);
          state.usersSelectInteractive = state.usersSelecetedView;
        }
      };

      if (checkedAll) {
        add ? addNewUsers() : removeUsersByArea();
      } else {
        toggleUserSelection();
      }
    },

    setUsersSelectInteractive(state, payload) {
      state.usersSelectInteractive.push(payload);
    },

    setInteractivePolicy(state, payload) {
      state.interactivePolicy = payload;
    },

    setUsersSelectedInteractive(state, payload) {
      const { checkedAll, add, users, _id, areas } = payload;

      const addNewUsers = () => {
        const newUsers = users.filter((user) => !state.usersSelectedInteractive.some((existingUser) => existingUser._id === user._id));
        state.usersSelectedInteractive.push(...newUsers);
      };

      const removeUsersByArea = () => {
        state.usersSelectedInteractive = state.usersSelectedInteractive.filter((user) => !areas.includes(user.area?._id || 1));
      };

      const toggleUserSelection = () => {
        const existingUserIndex = state.usersSelectedInteractive.findIndex((existingUser) => existingUser._id === _id);
        if (existingUserIndex !== -1) {
          state.usersSelectedInteractive.splice(existingUserIndex, 1);
        } else {
          state.usersSelectedInteractive.push(payload);
        }
      };

      if (checkedAll) {
        add ? addNewUsers() : removeUsersByArea();
      } else {
        toggleUserSelection();
      }
    },
    setNewLikeToContest(state, payload) {
      const { newLikeWithData, method } = payload;
 
      if (method === "create") {
        const indexUserLike = state.competition.likers.findIndex(({ user, _id }) => user._id === newLikeWithData.user._id || _id === newLikeWithData._id);

        if (indexUserLike === -1) {
          state.competition.likers.push(newLikeWithData);
        }
      } else {
        const indexUserLike = state.competition.likers.findIndex(({ _id }) => _id === newLikeWithData._id);

        if (indexUserLike !== -1) {
          state.competition.likers.splice(indexUserLike, 1);
        }
      }
    },
    setNewLikeToCommentContest(state, payload) {
      const { newLikeWithData, method } = payload;
      const comment = state.comments.find(({ _id }) => _id === newLikeWithData.likedItem);

      if (!comment) {
        return;
      }

      if (method === "create") {
        const indexUserLike = comment.likers.findIndex(({ user, _id }) => user._id === newLikeWithData.user._id || _id === newLikeWithData._id);

        if (indexUserLike === -1) {
          comment.likers.push(newLikeWithData);
        }
      } else {
        const indexUserLike = comment.likers.findIndex(({ _id }) => _id === newLikeWithData._id);

        if (indexUserLike !== -1) {
          comment.likers.splice(indexUserLike, 1);
        }
      }
    },
  },
  actions: {
    async getCompetitionById({ commit }, { _id }) {
      try {
        const {
          data: { contest },
          error,
        } = await this.$axios.get(`contest/byId/${_id}`);
        if (error) {
          throw Error(error);
        }

        if (contest) {
          commit("setCompetition", contest);
        }
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo obtener la encuesta");
      }
    },

    async getCompetitionsDb({ commit }, query = "") {
      try {
        commit("setCompetition", {});
        commit("setCompetitions", []);

        const {
          data: { contest, error },
        } = await this.$axios.get(`contest/${query}`);

        if (error) {
          throw Error(error);
        }

        const verifyContest = contest && contest.length ? contest : [];
        const headerCompetitions = verifyContest.filter((obj) => obj?.status === "active")[0] || {};

        commit("setCompetition", headerCompetitions);
        commit("setCompetitions", verifyContest);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    async getRankingDb(_, { query, contestId }) {
      try {
        const { data } = await this.$axios.get(`participant/ranking/${contestId}?${query}`);

        const { error, ranking, awards, hasMore, positionByRange } = data;

        if (error) {
          throw Error(error);
        }

        return { ranking, awards, hasMore, positionByRange };
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },

    async createNewCompetition({ commit }, newCompetition) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        commit("setNewCompetitions", []);
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}contest`, newCompetition, config);
        commit("setNewCompetitions", data);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async updateCompetition({ commit }, { newCompetition, _id }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.put(`${process.env.VUE_APP_API_IP}contest/${_id}`, newCompetition, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async registerCompetitionParticipant({ commit }, { contestId, newUser }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}participant/${contestId}`, newUser, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async updateCompetitionParticipant({ commit }, { contestId, newUser, userId }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.put(`${process.env.VUE_APP_API_IP}participant/${userId}?contestId=${contestId}`, newUser, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async removeCompetitionParticipant({ commit }, { contestId, userId }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.delete(`${process.env.VUE_APP_API_IP}participant/${userId}?contestId=${contestId}`, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async getCompetitionParticipant({ commit }, { contestId }) {
      try {
        const { data } = await this.$axios.get(`participant/${contestId}`);
        commit("setParticipants", data.participants);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },

    async getPointsDb({ commit }, { _id }) {
      try {
        const {
          data: { contest },
          error,
        } = await this.$axios.get(`points/${_id}`);
        if (error) {
          throw Error(error);
        }

        if (contest.length) {
          commit("setPoints", contest);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async getResultsDb({ commit }, { contestId, query }) {
      try {
        const {
          data: { ranking },
          error,
        } = await this.$axios.get(`participant/ranking/${contestId}?${query}`);

        if (error) {
          throw Error(error);
        }

        if (ranking.length) {
          commit("setResults", ranking);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async createCompetitionPoints(_, { idContest, formJson }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}points/${idContest}`, formJson, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async createCompetitionAward({ commit }, { idContest, formJson, action }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.put(`${process.env.VUE_APP_API_IP}award/${idContest}?action=${action}`, formJson, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async activeAllParticipants({ commit }, { idContest, actionParticipants }) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}participant/all/${idContest}?actionParticipants=${actionParticipants}`, config);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },

    async updateUsersViewCompetition({ commit }, { contestId, action, userId, police, policies }) {
      try {
        const {
          data: { error },
        } = await this.$axios.put(`${process.env.VUE_APP_API_IP}contest/polices/${contestId}?police=${police}&action=${action}&policies=${policies}`, {
          userId,
        });

        if (error) {
          throw new Error(error);
        }
        return;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async updateUsersInteractiveCompetition({ commit }, { contestId, action, userId, police }) {
      try {
        const {
          data: { error },
        } = await this.$axios.put(`${process.env.VUE_APP_API_IP}contest/policesinteractive/${contestId}?police=${police}&action=${action}`, {
          userId,
        });

        if (error) {
          throw new Error(error);
        }
        return;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async updatePaLikes({ state }, data) {
      const { like, userId } = data;

      const rankingToUpdate = state.ranking.find((ranking) => ranking._id === like.like.participantId);

      if (rankingToUpdate) {
        const likeIndex = rankingToUpdate.likes.findIndex((_like) => _like.userId._id === userId);

        if (like.status === "like") {
          rankingToUpdate.totalLikes++;
          rankingToUpdate.likes.unshift(like.like);
        } else if (like.status === "unlike" && likeIndex !== -1) {
          rankingToUpdate.totalLikes--;
          rankingToUpdate.likes.splice(likeIndex, 1);
        }
      }
    },
  },
};
