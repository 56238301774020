import AxiosConnect from "@/common/api/AxiosConnect";

export default {
  namespaced: true,
  state: () => ({
    dataAxiosLocal: {
      baseURL: process.env.VUE_APP_AUTLOCAL_API,
      timeout: 60000,
    },
    totalPercentaje: 0,
    intervalPercentaje: null,
    tryToOpenCounter: 0,
    proccesId: null,
  }),
  mutations: {
    setNewTry(state) {
      state.tryToOpenCounter++;
    },
    resetTryCounter(state) {
      state.tryToOpenCounter = 0;
    },
    setProcessId(state, id) {
      state.proccesId = id;
    },
  },
  actions: {
    async authJasminCam({ state, dispatch, commit }, user) {
      try {
        this.$alerts.dataSending({ title: "Abriendo Jasmin", message: "Se está abriendo la aplicación, espere un momento, por favor." });
        dispatch("setProgress");

        const { jasminUser, jasminPassword, ips } = user;
        const openApp = await dispatch("openJasminCam");

        if (!openApp) {
          throw new Error("No se abrió la aplicación, intenté nuevamente.");
        }
        let idProcessJcam = state.proccesId;

        const proccesId = await dispatch("getIdProcess");

        if (state.proccesId !== idProcessJcam) {
          idProcessJcam = proccesId;
        }
        if (!idProcessJcam) {
          throw new Error("No se encontró el proceso requerido.");
        }

        const body = { params: { param1: idProcessJcam, param2: jasminUser, param3: jasminPassword, param4: ips[0] } };
        await AxiosConnect.instance(state.dataAxiosLocal).post("/run-auth", body);

        this.$alerts.onChangeProgress(100);
        this.$alerts.closeSwal();
      } catch (error) {
        this.$alerts.closeSwal();
        this.$alerts.dataSendError({ message: error.message });

        console.log(error);
      } finally {
        clearInterval(state.intervalPercentaje);
        commit("setNewTry");

        state.totalPercentaje = 0;
      }
    },
    async authModelcenter({ state, dispatch, commit }, user) {
      try {
        this.$alerts.dataSending({ title: "Abriendo el modelcenter", message: "Se está abriendo la aplicación, espere un momento, por favor." });
        dispatch("setProgress");

        const { jasminUser, jasminPassword } = user;
        const body = { params: { param1: jasminUser, param2: jasminPassword } };

        await AxiosConnect.instance(state.dataAxiosLocal).post("/run-auth/modelcenterjcam", body);

        this.$alerts.onChangeProgress(100);
        this.$alerts.closeSwal();
      } catch (error) {
        this.$alerts.closeSwal();
        this.$alerts.dataSendError({ message: error.message });

        console.log(error);
      } finally {
        clearInterval(state.intervalPercentaje);
        commit("setNewTry");

        state.totalPercentaje = 0;
      }
    },
    async authStreamate({ state, dispatch, commit }, user) {
      try {
        this.$alerts.dataSending({ title: "Abriendo Streamate", message: "Se está abriendo la aplicación, espere un momento, por favor." });
        dispatch("setProgress");

        const { streamateUser, streamatePassword } = user;
        const body = { params: { param1: streamateUser, param2: streamatePassword } };

        await AxiosConnect.instance(state.dataAxiosLocal).post("/run-auth/streamate", body);

        this.$alerts.onChangeProgress(100);
        this.$alerts.closeSwal();
      } catch (error) {
        this.$alerts.closeSwal();
        this.$alerts.dataSendError({ message: error.message });

        console.log(error);
      } finally {
        clearInterval(state.intervalPercentaje);
        commit("setNewTry");

        state.totalPercentaje = 0;
      }
    },
    async openJasminCam({ state }) {
      try {
        const { data } = await AxiosConnect.instance(state.dataAxiosLocal).get("/openapp");
        const { isRunning = false } = data;

        return isRunning;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async getIdProcess({ state, commit }) {
      try {
        const { data } = await AxiosConnect.instance(state.dataAxiosLocal).get("/idwindows");
        const { handle = null } = data;
        commit("setProcessId", handle);
        return handle;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    setProgress({ state }) {
      const timmer = 550;

      state.intervalPercentaje = setInterval(() => {
        if (state.totalPercentaje < 99) {
          state.totalPercentaje += 1;
          this.$alerts.onChangeProgress(state.totalPercentaje);
        }
      }, timmer);
    },
    async closePlatform({ state }, platform) {
      try {
        const { data = {} } = await AxiosConnect.instance(state.dataAxiosLocal).get(`/stop/${platform}`);
        const { sucess = false } = data;

        return sucess;
      } catch (error) {
        console.log(error);
        return;
      }
    },
  },
  getters: {
    canAttemptOpen(state) {
      const now = new Date();
      const colombiaOffset = -5 * 60;
      const colombiaTime = new Date(now.getTime() + (now.getTimezoneOffset() + colombiaOffset) * 60 * 1000);
      const currentDay = colombiaTime.getUTCDay();
      const currentHour = colombiaTime.getHours();

      const isWithinSchedule = (currentDay > 1 || (currentDay === 1 && currentHour >= 5)) && (currentDay < 6 || (currentDay === 6 && currentHour < 5));

      if (isWithinSchedule) {
        return state.tryToOpenCounter >= 1;
      }

      return state.tryToOpenCounter >= 1;
    },
  },
};
