<script>
  export default {
    data() {
      return {
        isLoadingYears: true,
        years: [],
        yearActivePosition: -350,
      };
    },
    computed: {
      navYears() {
        const nextYear = new Date().getFullYear() + 1;
        const yearsExisting = this.years.map((year) => {
          const data = this.getYearData(+year._id);
          data.text += `: ${year.allData[0].name}`;
          data.contest = year.allData[0]._id;
          return data;
        });

        if (!this.verifyExistYear(this.years, nextYear)) {
          yearsExisting.push({ ...this.getYearData(nextYear), disabled: true });
        }
        return yearsExisting;
      },
      yearActive() {
        return this.navYears.find(({ contest }) => contest === this.$route.params?.id) || {};
      },
    },
    watch: {
      yearActive() {
        this.$nextTick(() => {
          this.yearActivePosition = this.getActivePosition();
          const parentElem = this.$refs.navYears;

          if (parentElem && this.yearActivePosition && this.yearActivePosition > 0) {
            parentElem.scrollTo({
              left: this.yearActivePosition,
              behavior: "smooth", 
            });
          }
        });
      },
    },
    methods: {
      getYearData(year) {
        const text = (new Date().getFullYear() >= year ? "GANADORAS " : "PRÓXIMAS GANADORAS ") + year;
        return { text, year: year + "" };
      },
      verifyExistYear(years, toVerify) {
        return years.some((year) => year._id === toVerify);
      },
      getActivePosition() {
        const parentElem = this.$refs.navYears;
        const elem = parentElem?.querySelector?.(".router-link-active");

        if (!elem || !parentElem) {
          return -350;
        }

        const elemPosition = elem.getBoundingClientRect();
        const parentPosition = parentElem.getBoundingClientRect();
        const positionLeftRelativeToParent = elemPosition.left - parentPosition.left + parentElem.scrollLeft;

        return positionLeftRelativeToParent;
      },
      async getYearsWithCompetition() {
        try {
          this.isLoadingYears = true;

          const { data = {} } = await this.$axios.get("/contest/years");
          const { years = [] } = data;

          this.years = years;
          this.isLoadingYears = false;
        } catch (error) {
          console.log(error);
        }
      },
      async getLastActiveOrFinished() {
        try {
          const { data = {} } = await this.$axios.get("/contest/last");
          const { lastCompetition } = data;

          return lastCompetition;
        } catch (error) {
          console.log(error);
        }
      },
      async setContestInitial() {
        await this.getYearsWithCompetition();
        const position = this.years.length - 1;

        this.$router.push({ name: "CompetitionSelected", params: { year: +this.years[position]._id, id: this.years[position].allData[0]._id } });
      },
    },
    beforeMount() {
      this.setContestInitial();
    },
  };
</script>

<template>
  <section class="rankingView">
    <div class="rankingView__navYears" ref="navYears">
      <router-link
        class="rankingView__year"
        v-for="(year, index) in navYears"
        :key="index"
        :to="year.disabled ? '' : { name: 'CompetitionSelected', params: { year: year.year, id: year.contest } }"
        :disabled="year.disabled">
        <span class="rankingView__yearDate">{{ year.text }}</span>
      </router-link>
      <div class="rankingView__year rankingView__year--active" :style="{ left: yearActivePosition + 'px' }">
        <span class="rankingView__yearDate">{{ yearActive.text }}</span>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>

<style lang="scss" scoped>
  .rankingView {
    $diagonal: 15deg;
    $radiusItems: $radius-4;

    @include Flex(column);
    width: 100%;
    gap: $space-16;

    &__navYears {
      position: relative;
      @include Flex(row, flex-start);
      width: 100%;
      height: 42px;
      padding: 0 3px;
      gap: $space-6;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      border-radius: $radiusItems;
      background: #f1f1f1;
      transform: skewX($diagonal);

      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #aaa;
      }
    }
    &__year {
      @include Flex(row);
      flex-shrink: 0;
      height: 95%;
      width: 860px;
      font-family: $sec_fontBold;
      font-size: 18px;
      text-decoration: none;
      border-radius: $radiusItems;
      background: linear-gradient(
        87.42deg,
        rgba(251, 243, 166, 0.2) 1.24%,
        rgba(253, 229, 127, 0.2) 4.09%,
        rgba(241, 211, 113, 0.2) 5.04%,
        rgba(202, 155, 67, 0.2) 8.84%,
        rgba(179, 120, 38, 0.2) 11.69%,
        rgba(170, 107, 28, 0.2) 13.59%,
        rgba(181, 123, 41, 0.2) 16.45%,
        rgba(209, 165, 75, 0.2) 23.1%,
        rgba(253, 229, 127, 0.2) 31.65%,
        rgba(251, 238, 152, 0.2) 38.31%,
        rgba(251, 243, 166, 0.2) 43.06%,
        rgba(253, 229, 127, 0.2) 66.83%,
        rgba(245, 218, 118, 0.2) 67.78%,
        rgba(213, 170, 79, 0.2) 72.53%,
        rgba(190, 135, 51, 0.2) 76.33%,
        rgba(176, 114, 34, 0.2) 79.18%,
        rgba(171, 107, 28, 0.2) 82.03%,
        rgba(173, 110, 30, 0.2) 83.94%,
        rgba(181, 121, 40, 0.2) 85.84%,
        rgba(193, 140, 55, 0.2) 87.74%,
        rgba(211, 167, 76, 0.2) 89.64%,
        rgba(234, 200, 104, 0.2) 91.54%,
        rgba(253, 229, 127, 0.2) 92.49%,
        rgba(251, 236, 147, 0.2) 94.39%,
        rgba(251, 242, 161, 0.2) 96.29%
      );
      color: #272727;
      box-shadow: 0px 1px 4px 0px #b57b2980;
      cursor: pointer;
      user-select: none;
      &--active {
        position: absolute;
        background: linear-gradient(
          87.42deg,
          #fbf3a6 1.24%,
          #fde57f 4.09%,
          #f1d371 5.04%,
          #ca9b43 8.84%,
          #b37826 11.69%,
          #aa6b1c 13.59%,
          #b57b29 16.45%,
          #d1a54b 23.1%,
          #fde57f 31.65%,
          #fbee98 38.31%,
          #fbf3a6 43.06%,
          #fde57f 66.83%,
          #f5da76 67.78%,
          #d5aa4f 72.53%,
          #be8733 76.33%,
          #b07222 79.18%,
          #ab6b1c 82.03%,
          #ad6e1e 83.94%,
          #b57928 85.84%,
          #c18c37 87.74%,
          #d3a74c 89.64%,
          #eac868 91.54%,
          #fde57f 92.49%,
          #fbec93 94.39%,
          #fbf2a1 96.29%
        );
        transition: all 0.3s ease-in-out;
        color: #ffffff;
        text-shadow: -1px 1px 2px #212121;
      }
      &[disabled] {
        cursor: default;
      }
    }
    &__yearDate {
      transform: skewX(-$diagonal);
    }
  }
</style>
