<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.css";
  export default {
    props: ["file"],
    data() {
      return {
        swiper: null,
        videoFit: [],
        fileLoaded: [],
        activeSlideIndex: 0,
        showControlIndex: 0,
      };
    },
    computed: {
      data() {
        return this.file.src.length;
      },
      handleShowControls() {
        return this.showControlIndex === this.activeSlideIndex && this.fileLoaded[this.activeSlideIndex];
      },
      handleShowBulletsAndArrows() {
        return this.data > 1;
      },
    },
    methods: {
      cutName(url) {
        const _url = url.split(".");
        return _url.length > 1 ? _url.pop().replaceAll("%20", " ") : _url;
      },
      validateType(file) {
        const types = ["jpg", "gif", "png", "jpeg", "webp"];
        return types.includes(this.cutName(file));
      },
      onVideoMetadata(video, index) {
        const aspectRatio = video.videoWidth / video.videoHeight;
        this.$set(this.videoFit, index, aspectRatio > 1 ? "cover" : "contain");
        this.$set(this.fileLoaded, index, true);
        video.controls = this.showControlIndex === index;
      },
      onImageLoad(index) {
        this.$set(this.fileLoaded, index, true);
      },
      showControls(index) {
        setTimeout(() => {
          this.showControlIndex = index;
        }, 1000);
      },
      initApp() {
        this.swiper = new Swiper(".swiper-container", {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: false,
          speed: 500,
          keyboard: {
            enabled: true,
            onlyInViewport: true,
          },
          lazy: {
            loadPrevNext: true,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            slideChange: () => {
              this.activeSlideIndex = this.swiper.activeIndex;
              this.showControls(this.activeSlideIndex);
              this.showControlIndex = null;
            },
          },
        });
        this.fileLoaded = new Array(this.file.src.length).fill(false);
      },
    },
    mounted() {
      this.initApp();
    },
  };
</script>

<template>
  <div class="image-player" v-if="file">
    <div class="image-player" v-if="typeof file.src === 'string'">
      <img :src="`${file.src}`" alt="modal-img" class="image-player__image" />
    </div>

    <div class="swiper-container" v-else>
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(file, index) in file.src" :key="index">
          <div class="loading" v-if="!fileLoaded[index]"></div>
          <div class="image-player__counterSlides">
            <span class="image-player__counter">{{ index + 1 }}</span
            >/{{ data }}
          </div>

          <div class="image-player__overlay image-player__overlay--left"></div>
          <div class="image-player__overlay image-player__overlay--right"></div>
          <img v-if="validateType(JSON.parse(file).location)" :src="`${JSON.parse(file).location}`" alt="modal-img" class="image-player__image" @load="onImageLoad(index)" />
          <video
            v-else
            class="image-player__video"
            :controls="handleShowControls"
            playsinline
            :autoplay="index === 0"
            :src="`${JSON.parse(file).location}#t=0.1`"
            @loadedmetadata="onVideoMetadata($event.target, index)"
            :style="{ objectFit: videoFit[index] || 'cover' }"
          ></video>
        </div>
      </div>
      <template v-if="handleShowBulletsAndArrows">
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
  .image-player {
    position: relative;
    height: auto;
    width: 100%;

    .loading {
      @include app_loading();
      &:before {
        background-image: url("../../assets/img/MySpace.png");
      }
    }

    .swiper-container {
      position: relative;
    }

    .swiper-wrapper {
      padding: $space-0 $space-0 $space-16;
    }

    .swiper-slide {
      aspect-ratio: 16/9;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      text-align: center;

      .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background-color: rgba(95, 94, 94, 0.514);
        opacity: 1;
        border-radius: 50%;
        margin: $space-0 6px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      .swiper-pagination-bullet-active {
        background: $primary_color;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      height: 75px;
      width: 75px;
      z-index: 2;
      transition: 0.4s;
      border-radius: 50%;
    }
    .swiper-button-prev {
      top: 50%;
      left: 15px;
      &::after {
        content: " " url("../../assets/img/arrowPrev.svg");
      }
    }
    .swiper-button-next {
      top: 50%;
      right: 15px;
      &::after {
        content: " " url("../../assets/img/arrow.svg");
      }
    }
    &__counter {
      font-weight: bold;
    }
    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 8%;
      height: 99.5%;
      pointer-events: none;
      z-index: 1;

      &--left {
        left: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
      }
      &--right {
        right: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &__counterSlides {
      position: absolute;
      right: 0;
      font-size: 16px;
      color: #fff;
      margin-top: 10px;
      margin-right: $space-8;
      background-color: rgba($color: #000000, $alpha: 0.3);
      padding: $space-2 $space-8;
      border-radius: $radius-12;
      z-index: 2;
    }
    &__image,
    &__video {
      width: 100%;
      height: 100%;
      object-position: center;
    }
    @media (min-width: 320px) {
      &__image {
        object-fit: cover;
      }
    }
    @media (min-width: 768px) {
      .swiper-button-prev,
      .swiper-button-next {
        @include Flex();
      }
    }
  }
</style>
