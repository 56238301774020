<template>
  <section class="infoUser">
    <div class="infoUser__wrapper" v-if="couldEditUser">
      <div class="infoUser__containerStatus">
        <p class="infoUser__title">Estado de usuario</p>
        <ToggleInput :checked="check" :label="check ? 'Usuario activo' : 'Usuario inactivo'" @isChecked="changeCheck" :key="reset" />
      </div>
      <div class="infoUser__containerStatus">
        <div v-if="user.dateIsEnable">
          <span class="infoUser__status">{{ `${!user.isEnable ? "Inactivación: " : "Activación: "}` }}</span>
          <span>{{ $global.dateLocaleFormat(new Date(user.dateIsEnable), options) }}</span>
        </div>
        <div v-if="user.whoIsEnable">
          <span class="infoUser__status">{{ "Por: " }}</span>
          <span>{{ user.whoIsEnable.user }}</span>
        </div>
        <div v-if="!user.isEnable && user.reasonDisable">
          <span class="infoUser__status">{{ "Motivo: " }}</span>
          <span>{{ optionsDisabled[user.reasonDisable] || user.reasonDisable }}</span>
        </div>
        <div v-if="user.descriptionDisabled">
          <span class="infoUser__status">{{ "Detalle: " }}</span>
          <span>{{ user.descriptionDisabled }}</span>
        </div>
        <div v-if="user.lastLogin">
          <span class="infoUser__status">{{ "Última sesión: " }}</span>
          <span>{{ $global.dateLocaleFormat(new Date(user.lastLogin), options) }}</span>
        </div>
        <div class="infoUser__contentTags" v-if="!user.isEnable && user.reasonDisable && (user.tagsDisabled || []).length">
          <div class="infoUser__tag" v-for="tag in user.tagsDisabled" :key="tag._id">{{ tag.name }}</div>
        </div>
      </div>
      <template v-if="user.license">
        <div class="infoUser__containerStatus">
          <span class="infoUser__status">{{ optionsDisabled[user.license.type] || user.license.type }}</span>
        </div>
        <div class="infoUser__containerStatus">
          <div>
            <span class="infoUser__status">{{ "Desde: " }}</span>
            <span>{{ $global.dateLocaleFormat(user.license.date, options) }}</span>
          </div>
          <div>
            <span class="infoUser__status">{{ "Hasta: " }}</span>
            <span>{{ $global.dateLocaleFormat(user.license.dateTo, options) }}</span>
          </div>
          <div v-if="user.license.descriptionLicense">
            <span class="infoUser__status">{{ "Descripción: " }}</span>
            <span>{{ user.license.descriptionLicense }}</span>
          </div>
        </div>
      </template>
    </div>
    <div class="infoUser__wrapper">
      <div class="infoUser__header">
        <p class="infoUser__title">Información General</p>
        <a class="editUBtn" role="button" aria-label="Editar Usuario" v-if="couldEditUser" @click="openEditModal">
          <iconic class="infoUser__iconEdit" name="editNew" />
        </a>
      </div>
      <div class="infoUser__containerData">
        <div class="infoUser__containerInfo" v-for="(data, idx) in dataToShow" :key="idx">
          <span class="infoUser__infoName">{{ data.name }}</span>
          <span class="infoUser__infoValue">{{ data.value }}</span>
        </div>
      </div>
    </div>
    <UserMagnamentState v-if="showModalDisabled" :closeModal="closeModal" :setUpdateUser="setUpdateUser" />
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  export default {
    components: {
      ToggleInput: () => import("@/components/input/ToggleInput.vue"),
      UserMagnamentState: () => import("@/components/modals/UserMagnamentState.vue"),
    },
    data() {
      return {
        check: false,
        showModalDisabled: false,
        reset: 1,
        dicKeys: {
          name: "Nombre completo",
          documentType: "Tipo de documento",
          documentId: "Doc. Identificación",
          email: "Correo",
          phone: "Celular",
          city: "Ciudad",
          office: "Oficina",
          workShift: "Turno",
          room: "Habitación",
          entrydate: "Fecha de ingreso",
          sessionLocalIPs: "Ips ultima sesión",
        },
        dicTurns: {
          morning: "Mañana",
          late: "tarde",
          night: "noche",
        },
        options: { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12" },
      };
    },
    computed: {
      ...mapGetters("applications", {
        getAppByName: "getAppByName",
      }),
      user() {
        return this.$store.state.user.userSelected;
      },
      dataToShow() {
        const { user = {} } = this;
        const typesDocuments = this.$global.dictionary.documentType;
        const { name, documentType, documentId, email, phone, ...dicKeys } = this.dicKeys;
        const dataToShow = this.$userData.role !== "superadmin" ? dicKeys : this.dicKeys;
        const responseValues = {
          name: (user) => this.formatFullName(user),
          workShift: (user) => this.getWorkShiftName(user),
          entrydate: ({ entrydate }) => this.formatDate(entrydate),
          documentType: ({ documentType }) => typesDocuments[documentType] || typesDocuments["idcard"],
        };

        const data = Object.entries(dataToShow).reduce((acc, [key, valDic]) => {
          if (!user.hasOwnProperty(key)) {
            return acc;
          }
          const name = valDic;
          const value = responseValues.hasOwnProperty(key) ? responseValues[key](user) : user[key] && user[key] !== "null" ? user[key] : "";
          acc.push({ name, value });
          return acc;
        }, []);
        return data;
      },
      couldEditUser() {
        return ["superadmin", "coordinator"].includes(this.$userData.role);
      },

      optionsDisabled() {
        const optionsDisabled = {
          endContract: this.user.role === "model" ? "Finalización alianza" : "Termina contrato",
          waiver: "Renuncia",
          deserter: "Deserta",
          vacaciones: "Vacaciones",
          incapacidad: "Incapacidad",
          licencia: "Licencia",
        };
        return optionsDisabled;
      },
    },
    watch: {
      user({ isEnable }) {
        this.check = isEnable;
      },
    },
    methods: {
      formatFullName(user) {
        const { name, lastname } = user;
        return `${name ?? ""} ${lastname ?? ""}`;
      },
      getWorkShiftName(user) {
        return user?.workShift?.name ? this.dicTurns[user.workShift.name] : "";
      },
      getIsEnableOrRasonDisabled(user) {
        const textIsEnable = user.isEnable ? "activo" : "";
        const textDisabled = user?.reasonDisable ? user.reasonDisable : "inactivo";
        return textIsEnable || textDisabled;
      },
      formatDate(date) {
        if (!date) return "";
        return this.$global.dateLocaleFormat(date, this.options);
      },
      openEditModal() {
        this.$store.dispatch("user/setInfoToEdit");

        this.$store.state.user.toShowInMagnament = 1;
      },
      closeModal() {
        this.showModalDisabled = false;
        this.reset++;
      },
      async changeCheck(event) {
        if (!event) {
          this.showModalDisabled = true;
          return;
        }
        await this.setUpdateUser({ isEnable: event }, event);
      },
      async setUpdateUser(body) {
        body._id = this.user._id;
        body.host = this.getAppByName("reservations").api || "";

        const { success, error, userEdited } = await this.$store.dispatch("user/updateUser", body);
        const msgSwal = {
          text: error ? error : "Usuario editado correctamente.",
          icon: success ? "success" : "error",
          confirmButtonColor: "#bd0909",
        };

        Swal.close();
        Swal.fire(msgSwal);

        this.check = userEdited.isEnable;
        this.reset++;
      },
      motiveDisable() {
        if (!this.showDisable) {
          this.showDisable = true;
          return;
        }
        if (this.showDisable) {
          this.update();
        }
        this.showDisable = false;
      },
    },
    beforeMount() {
      this.check = this.user.isEnable;
    },
  };
</script>

<style lang="scss">
  .infoUser {
    height: fit-content;
    padding: $space-4;
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: $mpadding;
    &__wrapper {
      width: 100%;
      padding: $mpadding;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
    &__containerStatus {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 5px;
      padding: 5px;
      text-overflow: ellipsis;
      overflow: auto;
    }
    &__header {
      @include Flex(row, space-between);
    }
    &__title {
      font-family: $sec-font;
      font-size: 20px;
    }
    &__iconEdit {
      padding: 5px;
      font-size: 20px;
      color: $chicago;
      cursor: pointer;
    }
    &__containerData {
      display: grid;
      grid-template-columns: 1fr;
    }
    &__containerInfo {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding: 5px;
      text-overflow: ellipsis;
      overflow: auto;
      border-bottom: 1px solid $alto;
    }

    &__infoName {
      text-transform: capitalize;
      color: #9d9d9d;
    }
    &__infoValue {
      text-transform: capitalize;
      color: #495057;
    }
    &__status {
      font-weight: bold;
      color: $chicago;
    }
    &__contentTags {
      @include Flex(row, flex-start);
      flex-wrap: wrap;
      gap: 5px;
    }
    &__tag {
      padding: 2px 10px;
      text-transform: capitalize;
      font-size: 15px;
      border-radius: 14px;
      background: #f1f1f1;
      user-select: none;
    }
    .editUBtn {
      display: inline-flex;
    }
    .editUBtn * {
      pointer-events: none;
    }
    .editUBtn:active * {
      color: $primary_color;
    }
  }
</style>
