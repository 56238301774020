<script>
  import Likes from "../../reactions/likes/Likes.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "CommentItem",
    components: { Likes },
    props: {
      comment: {
        type: Object,
        required: true,
      },
      allComments: {
        type: Array,
        required: true,
      },
      numberBlock: {
        type: Number,
        default: 0,
      },
      canPinMessage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showReplies: false,
        isHover: false,
        showMore: false,
      };
    },
    computed: {
      ...mapGetters(["screen"]),
      payload() {
        return {
          item: this.comment._id,
          component: "commentsContest",
        };
      },
      replies() {
        return this.allComments.filter((c) => c.parentComment === this.comment._id);
      },
      showPin() {
        return this.canPinMessage || this.comment.isPinned;
      },
      styles( ){
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        const styles = {
          color: this.comment.isPinned ? "#bd0909" : "#656565",
        }
        if (!this.canPinMessage){
          return styles;
        }
        if(!isTouchDevice && this.isHover) {
          styles.color = styles.color === "#bd0909" ? "#656565" : "#bd0909";
        }
        styles.cursor = "pointer";

        return styles
      },
      textLength() {
        return this.screen.width < 768 ? 420 : 770;
      },
      exceedsLength() {
        return this.comment.comment.length > this.textLength;
      },
      displayedComment() {
        return this.exceedsLength && !this.showMore ? this.comment.comment.substring(0, this.textLength) : this.comment.comment;
      },
      toggleText() {
        return this.showMore ? "Ver menos." : "Ver más.";
      },
    },
    methods: {
      toggleExpand() {
        this.showMore = !this.showMore;
        this.insertCommentContent();
      },
      insertCommentContent() {
        if (!this.$refs.commentContent) return;
        const content = this.exceedsLength && !this.showMore ? `${this.displayedComment.trim()}...` : this.displayedComment;
        this.$refs.commentContent.innerHTML = content;
      },
      canPin() {
        if (!this.canPinMessage) {
          return;
        }
        this.$emit("pinMessage", this.comment);
      },
      setHoverPin(newValue) {
        if (!this.canPinMessage) {
          return;
        }
        this.isHover = newValue;
      },
    },
    mounted() {
      this.insertCommentContent();
    },
  };
</script>

<template>
  <div class="commentItem">
    <div class="commentItem__comment">
      <div class="commentItem__infoContainer">
        <Avatar class="commentItem__avatar" :user="comment.user" />
        <div class="commentItem__content">
          <div class="commentItem__header">
            <p class="commentItem__text">
              {{ comment.user?.artisticName || comment.user?.user }}
            </p>
            <div class="commentItem__dateAndPin">
              <div class="commentItem__pin" :style="styles" @click="canPin" @mouseenter="() => setHoverPin(true)" @mouseleave="() => setHoverPin(false)" v-if="showPin">
                <iconic name="pin" />
              </div>
              <p class="commentItem__date">
                {{ comment.timeLapsed }}
              </p>
            </div>
          </div>
          <div class="commentItem__info">
            <div class="commentItem__commentContent">
              <span ref="commentContent"></span>
              <span class="commentItem__toggleExpand" @click="toggleExpand" v-if="exceedsLength">{{toggleText}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="commentItem__options">
        <Likes :likes="comment.likers" :showLikes="true" :payload="payload" v-if="comment.likers" :openLikes="() => $emit('openLikes', comment.likers)" />
        <div class="commentItem__response" @click="$emit('setParentCommentId', comment)" v-if="numberBlock !== 2">Responder</div>
      </div>
    </div>
    <div class="commentItem__answers">
      <button @click="showReplies = !showReplies" class="commentItem__toggleReplies" v-if="replies.length">
        {{ showReplies ? "Ocultar respuestas" : `Ver ${replies.length} respuestas más` }}
      </button>
      <div :class="['commentItem__repliesContainer', { 'commentItem__repliesContainer--open': showReplies }]">
        <CommentItem
          v-for="reply in replies"
          :key="reply._id"
          :comment="reply"
          :allComments="allComments"
          :numberBlock="numberBlock + 1"
          @openLikes="$emit('openLikes', reply.likers)"
          @setParentCommentId="$emit('setParentCommentId', reply)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .commentItem {
    gap: $space-4;
    width: 100%;
    &__avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid $primary-color;
    }
    &__comment {
      @include Flex(column, flex-start, flex-start);
      position: relative;
      margin-top: $space-6;
      width: 100%;
    }
    &__commentContent {
      word-break: break-word;
    }
    &__infoContainer {
      @include Flex(row, flex-start, flex-start);
      gap: $space-6;
      margin-top: $space-6;
      width: 100%;
    }
    &__options {
      @include Flex(row, flex-start, center);
      width: 50%;
      margin-left: 50px;
    }
    &__dateAndPin {
      @include Flex(row-reverse, center, center);
      gap: $space-8;
    }
    &__pin {
      @include Flex(row, center, center);
      color: #656565;
      padding: $space-6;
      transition: 0.4s ease;
    }
    &__header {
      @include Flex(row, space-between, center);
      width: 100%;
    }
    &__date {
      font-size: 12px;
      color: #9d9d9d;
    }
    &__toggleReplies {
      margin-left: $space-16;
      color: #000;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 2;
      &:hover {
        text-decoration: underline;
      }
    }
    &__toggleExpand {
      display: inline-block;
      color: $primary-color;
      font-weight: 700;
      margin-left: $space-8;
      cursor: pointer;
      z-index: 2;
      &:hover {
        text-decoration: underline;
      }
    }
    &__repliesContainer {
      overflow: hidden;
      width: 100%;
      max-height: 0;
      transition: max-height 0.4s linear;
      &--open {
        max-height: 800px;
      }
    }
    &__content {
      @include Flex(column, flex-start, flex-start);
      gap: $space-8;
      width: 100%;
      padding: $space-8 $space-16;
      border-radius: $radius-16;
      background-color: #9d9d9d1c;
    }
    &__text {
      font-size: 16px;
      font-family: $newFont_semiBold;
    }
    &__response {
      font-size: 12px;
      color: #000;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &__answers {
      @include Flex(column, flex-start, flex-start);
      width: 80%;
      height: 100%;
      margin-left: $space-32;
      padding-left: $space-8;
    }
    &__info {
      word-break: break-all;
    }
  }
</style>
