<script>
  import { startCyclicChange } from "../../js/utils.js";
  export default {
    components: {
      Confetti: () => import("../art/Confetti.vue")
    },
    props: {
      modalData: {
        type: Object,
        required: true,
      },
      selectedAnimation: {
        type: String,
        default: '',
      },      
    },
    data() {
      return {
        currentImageIndex: 0,
        showAnimation: false,
        imageInterval: null,
        conffetiTimeOut: null
      };
    },
    computed: {
      currentImage() {
        return this.modalData.images[this.currentImageIndex];
      },
      modalImagesLength() {
        return this.modalData.images.length;
      },
      setBgColor() {
        return this.modalImagesLength === 1 ? "background: white" : "background: rgba(255, 255, 255, 0.75)";
      },
    },
    methods: {
      setImageChange() {
        if (this.modalImagesLength <= 1) {
          return;
        }

        clearInterval(this.imageInterval);

        this.imageInterval = startCyclicChange(this.modalImagesLength, 1700, (newIndex) => {
          this.currentImageIndex = newIndex;
        });
      },
      setShowAnimation(){
        if(this.showAnimation){
          return;
        }

        this.showAnimation = true;

        clearTimeout(this.conffetiTimeOut)
        this.conffetiTimeOut = setTimeout(() => {
          this.showAnimation = false;
        }, 3000)
      }      
    },
    mounted() {
      this.setImageChange();
    },
    beforeDestroy() {
      clearInterval(this.imageInterval);
    },
  };
</script>

<template>
  <div class="modalCounter" :style="setBgColor">
    <div class="modalCounter__fullImage" v-if="modalData.fullImage">
      <img :src="modalData.images[0]" class="modalCounter__imageFull" alt="modalCounter-img" />
    </div>
    <div class="modalCounter__container" v-else>
      <div class="modalCounter__imageContainer">
        <transition name="fade">
          <img :key="currentImageIndex" :src="currentImage" alt="event image" class="modalCounter__image" />
        </transition>
      </div>
      <div class="modalCounter__content" v-if="modalData">
        <div class="modalCounter__title">{{ modalData.title }}</div>
        <div class="modalCounter__description">{{ modalData.description }}</div>
        <div class="modalCounter__date">
          Faltan
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="modalCounter__confettiWrapper" >
      <transition name="fade">
        <component :is="selectedAnimation" v-if="showAnimation" />
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
  .modalCounter {
    position: relative;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: $radius-12;
    width: 250px;
    &__fullImage {
      width: 100%;
      height: 100%;
      padding: 10px 10px 17px;
    }
    &__imageFull {
      border-radius: $radius-12;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__images {
      width: 50%;
      height: 100%;
    }
    &__container {
      @include Flex(column);
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    &__imageContainer {
      position: relative;
      background: transparent;
      width: 100%;
      height: 150px;
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    &__content {
      @include Flex(column, center, center);
      width: 110%;
      flex: 1;
      padding: $space-12;
      gap: 10px;
      -webkit-box-shadow: 0px -20px 38px -1px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px -20px 38px -1px rgba(255, 255, 255, 1);
      box-shadow: 0px -20px 38px -1px rgba(255, 255, 255, 1);
      z-index: 1;
    }
    &__title {
      font-size: 20px;
      font-family: $sec_font;
      font-weight: bold;
      color: #333;
      text-align: center;
      line-height: 1.1;
    }
    &__description {
      font-size: 15px;
      color: #272727;
      font-family: $newFont_regular;
      text-align: center;
    }
    &__date {
      @include Flex();
      gap: 3px;
      color: #272727;
      font-size: 18px;
      font-family: $newFont_medium;
      font-weight: bold;
      text-align: center;
    }
    &__confettiWrapper{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @include pocketWidth() {
      width: 350px;
      &__imageContainer {
        height: 200px;
      }
    }
    @include tabletWidth() {
      width: auto;
      &__fullImage {
        width: 350px;
      }
      &__container {
        flex-direction: row;
        height: 210px;
        width: 450px;
      }
      &__imageContainer {
        width: 50%;
        height: 250px;
      }
      &__content {
        padding: $space-20;
        -webkit-box-shadow: -21px 0px 38px -1px rgba(255, 255, 255, 1);
        -moz-box-shadow: -21px 0px 38px -1px rgba(255, 255, 255, 1);
        box-shadow: -21px 0px 38px -1px rgba(255, 255, 255, 1);
      }
      &__title {
        font-size: 29px;
      }
      &__description {
        width: 100px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
