<script>
  import { mapActions, mapMutations } from "vuex";
  export default {
    components: {
      Comment: () => import("@/components/news/comments/CommentsInput.vue"),
      Likes: () => import("../../components/Competitions/likes/Likes.vue"),
      UsersListVisor: () => import("../widgets/Modals/UsersListVisor.vue"),
    },
    props: ["participant", "award", "comments", "showComments"],
    data() {
      return {
        showModalLikes: false,
      };
    },
    methods: {
      ...mapActions("comments", ["getCommentsByModel"]),
      ...mapMutations("comments", ["setComment"]),
      closeModal() {
        this.$emit("close");
        this.setComment([]);
      },
      capitalizeFirstLetter(value) {
        if (!value) return "";
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      getNameComment(userData = {}) {
        const { artisticName, user } = userData;
        return artisticName || user;
      },
      showLikesMd(event) {
        event.stopPropagation();
        this.showModalLikes = !this.showModalLikes;
      },
      setModeScroll(mode = "initial") {
        if (!document) {
          return;
        }

        document.body.style.overflowY = mode;
      },
    },
    computed: {
      dataComments() {
        return this.comments.reverse();
      },
      imgParticipant() {
        return this.participant.internalPicture;
      },
      positionParticipant() {
        return this.participant.position;
      },
      imgAward() {
        return this.award && this.award?.logo;
      },
      nameUser() {
        const { artisticName, user } = this.participant.user;
        return artisticName || user;
      },
      cityUser() {
        return this.participant.user.city;
      },
      screen() {
        return this.$screen.width;
      },
      participantLikes() {
        const likes = this.participant?.likes || [];
        return likes.map((it) => it.userId);
      },
    },
    beforeMount() {
      this.setModeScroll("hidden");
    },
    beforeDestroy() {
      this.setModeScroll();
    },
  };
</script>

<template>
  <div class="competitions__modalContainer">
    <div @click="closeModal" class="competitions__overlay"></div>
    <div class="competitions__dataContainer">
      <div v-if="screen <= 1200" class="competitions__dataHeader">
        <p class="competitions__nameModal">{{ nameUser }}</p>
        <div @click="closeModal">
          <iconic class="competitions__closeModal" name="close" />
        </div>
      </div>
      <div class="competitions__dataImage">
        <img :src="imgParticipant" alt="participante" />
      </div>
      <div class="competitions__dataInfo">
        <div v-if="screen > 1200" class="competitions__dataHeader">
          <p class="competitions__nameModal">{{ nameUser }}</p>
          <div @click="closeModal">
            <iconic class="competitions__closeModal" name="close" />
          </div>
        </div>
        <div class="competitions__dataBody" :class="showComments ? 'comment--height' : ''">
          <div>
            <div v-for="(comment, index) in dataComments" :key="index" class="competitions__comments">
              <img :src="comment.user.picture" :alt="getNameComment(comment.user)" class="competitions__imgComments" />
              <div class="competitions__infoComment">
                <p class="competitions__nameUser">{{ getNameComment(comment.user) }}</p>
                <p class="competitions__textComment">{{ capitalizeFirstLetter(comment.comment) }}</p>
              </div>
            </div>
          </div>
          <div v-if="showComments">
            <Comment class="competitions__comment" :participant="participant" />
          </div>
        </div>
        <div class="competitions__dataFooter">
          <div class="competitions__footerContainer">
            <Likes
              :likes="participant.likes"
              :totalLikes="participant.totalLikes"
              :postId="participant._id"
              nameEvent="likeParticipants"
              endpoint="/participants/likes"
              :modalaction="showLikesMd"
              :showBlack="true"
              class="competitions__likes" />
          </div>
          <div class="competitions__footerContainer">
            <iconic name="commentContest" />
            <span class="competitions__cantComments">{{ comments.length }}</span>
          </div>
        </div>
      </div>
    </div>
    <UsersListVisor :users="participantLikes" :detail="'Usuarios que han dado like a la participante:'" :closeModal="showLikesMd" v-if="showModalLikes" />
  </div>
</template>

<style lang="scss">
  .competitions {
    &__modalContainer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include Flex();
      z-index: 150;
      background-color: #00000099;
    }
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    &__dataContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: -168px;
    }
    &__dataImage {
      width: 800px;
      overflow: hidden;
      aspect-ratio: 4 / 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__dataInfo {
      position: relative;
      width: 400px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: 1px solid lightgray;
      overflow: hidden;
      background: #fff;
    }
    &__dataHeader {
      @include Flex(row, space-between);
      width: 100%;
      height: 50px;
      padding: 10px;
      border-bottom: 1px solid lightgray;
      background: #fff;
    }
    &__nameModal {
      color: $primary-color;
      font-family: $sec_font;
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      line-height: 0px;
    }
    &__closeModal {
      color: $primary-color;
      cursor: pointer;
    }
    &__dataBody {
      padding: 10px;
      max-height: 400px;
      overflow-y: auto;
      .comment--height {
        max-height: 340px;
      }
      .comments-input {
        width: 380px;
        position: absolute;
        bottom: 50px;
        background-color: #f3f3f3;
      }
      .input-container {
        @include Flex(row, space-between);
        gap: $space-6;
      }
      .input-avatar {
        width: 30px;
        height: 30px;
      }
      .input-send {
        margin-left: 20px;
        margin-right: 10px;
        border: none;
        background-image: url("https://new-web-site-bucket.s3.amazonaws.com/dev/concursos/send.svg");
        background-size: cover;
        cursor: pointer;
        text-indent: -9999px;
        width: 20px;
        height: 20px;
        &:focus {
          outline: none;
        }
        &:hover {
          transform: scale(1.2);
        }
      }
      .comment-input {
        outline: none;
      }
      .input-emojis {
        background: lightgray;
        font-size: 15px;
      }
      .input-input {
        width: 240px;
        height: 30px;
        border: none;
        background: transparent;
      }
    }
    &__comments {
      display: flex;
      gap: 15px;
      padding: 5px 15px;
      align-items: center;
    }
    &__imgComments {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid $primary-color;
    }
    &__nameUser {
      color: #000;
      font-family: $sec_font;
      font-size: 16px;
      font-style: normal;
    }
    &__dataFooter {
      position: absolute;
      bottom: 0;
      display: flex;
      align-content: center;
      height: 38px;
      width: 100%;
      padding: 10px;
      gap: 20px;
      border-top: 1px solid lightgray;
      background: white;
    }
    &__footerContainer {
      margin-right: -10px;
    }
    &__likes {
      position: relative;
      top: -10px;
    }
    &__cantComments {
      position: absolute;
      top: 7px;
      margin-left: 5px;
    }
    @media (min-width: 768px) {
      &__dataContainer {
        margin-top: -108px;
      }
    }
    @media (min-width: 1201px) {
      &__dataContainer {
        margin-top: 0;
      }
    }
    @media (max-width: 1200px) {
      &__dataFooter {
        bottom: -37px;
        border-bottom: 1px solid lightgray;
      }
      &__dataContainer {
        flex-direction: row-reverse;
        width: 100%;
        max-width: 600px;
        padding-left: 2%;
        padding-right: 2%;
      }
      &__dataImage {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        img {
          object-fit: cover;
        }
      }
      &__dataHeader {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &__dataInfo {
        width: 100%;
        overflow: initial;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-left: none;
        border-top: 1px solid lightgray;
      }
      &__dataBody {
        padding: 0px;
        height: 180px;
        .comments-input {
          width: 100%;
          bottom: -95px;
          background: white;
          border-radius: 0 0 10px 10px;
        }

        .input-emojis {
          display: none;
        }
      }
    }
  }
</style>
