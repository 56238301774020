<template>
  <div class="chat__wrapper">
    <div  :class="unReadmessage.length ? 'notification-unread-messages-btn' : 'btn-messages'" @click="toogleChat" v-if="isMinimized">
      <span v-if="unReadmessage.length" :class="unReadmessage.length > 99 ? 'more-messages' : 'count-messages'">{{ unReadmessage.length }}</span>
      <iconic name="chat" class="icon" />
    </div>
    <div @mouseleave="mouseOut" @mouseenter="enterChat" :class="!isMinimized ? 'chat' : ''">
      <div class="chat-title-container" @click="toogleChat">
        <h1 class="chat-title">{{ chatGroup.name }}</h1>
        <iconic name="close" v-if="!isMinimized" />
      </div>
      <template v-if="!isMinimized">
        <ChatMessages class="chat-messages-container" />
        <form :class="!horn ? 'chat-send-messages-form' : 'report-error'" @submit.prevent="submit">
          <div class="chat-input">
            <label for="text"></label>
            <input :class="inputsClass('chat-send-message-input', ' chat-send-message-input handDisable')" type="text" v-model="text" id="text" placeholder="Escribir mensaje" />
          </div>
          <div :class="inputsClass('chat-buttons', 'chat-buttons handDisable')">
            <div class="chat-send-message-help">
              <div :class="!hasModels && 'handDisable'" @click="toggleState('popupSendModels')">
                <iconic title="pedir ayuda" class="chat-send-message-help-hand" name="hand" />
              </div>
              <div @click="toggleState('horn')">
                <iconic title="reportar daño" class="chat-send-message-help-horn" :name="!horn ? 'horn' : 'horn2'" />
              </div>
            </div>
            <button type="submit" class="send">
              <iconic :class="text ? 'sendarrow' : 'sendarrowdisable'" name="sendmessage" />
            </button>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
  import { socket } from "@/socket";
  import { mapGetters, mapActions } from "vuex";

  export default {
    components: {
      ChatMessages: () => import("./ChatMessages.vue"),
    },
    data() {
      return {
        text: "",
        readTimeOut: null,
        mouseInChat: false,
      };
    },
    computed: {
      ...mapGetters("connectedUsers", {
        modelsAssigned: "modelsAssigned",
      }),
      ...mapGetters("chat", {
        unReadmessage: "unReadMessages",
      }),
      messages() {
        return this.$store.state.chat.messagesChat;
      },
      chatGroup() {
        return this.$store.state.chat.chatGroup;
      },
      isMinimized() {
        return this.$store.state.chat.isMinimized;
      },
      horn() {
        return this.$store.state.chat.horn;
      },
      profileUser() {
        return this.$store.state.user.profile;
      },
      hasModels() {
        return this.modelsAssigned?.length;
      },
      focusWindow() {
        return this.$store.state.user.userInWindow;
      },
      sizeWindow() {
        return this.$store.state.screen.width;
      },
      isSuperAdminOrMonitor() {
        return this.profileUser.role === "superadmin" || this.profileUser.role === "monitor";
      },
    },
    mounted() {
      this.getMessages();
      this.getGroupName();
      window.addEventListener("resize", this.handleResize);
      socket.on("newMessage", async (payload) => {
        if (!payload) return;

        const msgFind = this.messages.findIndex((ele) => ele._id === payload._id);
        if (msgFind !== -1) {
          return;
        }

        this.messages.push(payload);
        if (!this.focusWindow) {
          this.notifyMessage("Mensaje nuevo...", payload.message, payload.fromUser.picture);
        }

        await this.sleep(100);
        this.messagesScroll();
      });

      socket.on("updateChatMessage", (payload) => {
        payload.forEach((message) => {
          const messageIdx = this.messages.findIndex((msg) => msg._id === message._id);
          if (messageIdx !== -1) {
            this.messages.splice(messageIdx, 1, message);
          }
        });
      });
    },

    methods: {
      ...mapActions("chat", {
        getMessages: "getMessages",
        getGroupName: "getGroupName",
        sendMessages: "sendMessages",
        updatePopUpSendModelsState: "updatePopUpSendModelsState",
        toggleState: "toggleState",
      }),
      mouseOut() {
        this.mouseInChat = false;
      },
      enterChat() {
        this.mouseInChat = true;
        this.readMessages();
      },
      handleResize() {
        const isMobile = /Android|iPhone|iPad/i.test(navigator.userAgent);
        if (!this.isMinimized && (!isMobile || window.visualViewport?.height === window.innerHeight)) {
          this.toogleChat();
        }
      },
      async readMessages() {
        try {
          if (!this.isMinimized && this.mouseInChat && this.unReadmessage.length) {
            clearTimeout(this.readTimeOut);
            this.readTimeOut = setTimeout(async () => {
              await this.$axios.put("/chat", { user: this.profileUser._id });
              return;
            }, 5000);
            return;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      },

      async submit() {
        const newMessage = {
          message: this.text,
          toUsers: this.chatGroup._id,
          group: this.chatGroup._id,
        };
        if (this.horn) {
          newMessage.errorMsg = true;
          this.toggleState("horn");
        }
        this.sendMessages(newMessage);

        this.text = "";
      },

      positionViewInMessageNotRead() {
        const unreadMsg = document.querySelector(".chat-messages-container .unread-messages");

        if(!unreadMsg || !unreadMsg?.parentNode) {
          return;
        };

        const div = document.querySelector(".unread-msg");
        unreadMsg.parentNode.insertBefore(div, unreadMsg);
        const lastMessageUnRead = document.querySelector(".chat-messages-container .unread-msg");
        lastMessageUnRead.scrollIntoView();
      },

      async messagesScroll() {
        const inUserInWindow = this.$store.state.user.userInWindow;
        if (!this.isMinimized) {
          if (inUserInWindow && this.unReadmessage.length) {
            this.positionViewInMessageNotRead();
            this.readMessages();
            return;
          }
          const lastMessage = document.querySelector(".chat-messages-container .own-messages:last-child");
          if (lastMessage) {
            lastMessage.scrollIntoView();
          }
        }
      },

      async toogleChat() {
        this.toggleState("isMinimized");
        await this.sleep(100);
        this.messagesScroll();
        if (!this.isMinimized && this.sizeWindow < 435) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      },

      notifyMessage(title, msg, picture) {
        if (!this.focusWindow) {
          parent.postMessage({ message: "notification", data: { title, body: msg } }, "*");
          if (Notification.permission === "granted") {
            const notification = new Notification(title, {
              icon: picture,
              body: msg,
            });

            notification.addEventListener("click", () => {
              window.focus();
            });
            notification.addEventListener("show", () => {
              setTimeout(() => {
                notification.close();
              }, 5000);
            });
          }
        }
      },
      inputsClass(allowedClass, deniedClass) {
        return this.isSuperAdminOrMonitor ? allowedClass : deniedClass;
      },
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
</script>

<style lang="scss">
  .chat,
  .notification-unread-messages {
    min-height: 40px;
    height: 580px;
    min-height: 40px;
    width: 350px;
    z-index: 10;
    border-radius: 10px;
    box-shadow: -2px 0px 16px rgba(0, 0, 0, 0.25);
    background-color: white;
    z-index: 200;

    .chat-title-container {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 10px 20px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      .chat-title {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .notification-unread-messages-btn,
  .btn-messages {
    width: 55px;
    height: 55px;
    display: flex;
    background: #fff;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0.87px 6.08px 0px #00000040;
    z-index: 40;
    cursor: pointer;

    .count-messages,
    .more-messages {
      position: absolute;
      right: 0;
      color: #fff;
      font-size: 13px;
      font-family: $newFont_semiBold;
      border: 1px solid #fff;
      display: flex;
      justify-content: center;
      background: rgb(36, 36, 36);
    }
    .count-messages {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    .more-messages {
      width: 37px;
      height: 20px;
      border-radius: 15px;
    }
    .icon {
      color: $primary-color;
      font-size: 30px;
    }
  }
  .notification-unread-messages-btn {
    animation: change-color 3s ease-in-out infinite;
    .icon {
      color: #fff;
      font-size: 34px;
    }
  }

  .handDisable {
    pointer-events: none;
    opacity: 0.6;
  }

  .chat-send-messages-form,
  .report-error {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 78px;
    border-radius: 24px;
    background-color: #f6f6f6;
    margin: 0 22px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    .chat-send-message-input {
      width: 100%;
      height: 40px;
      padding: 0 15px;
      border: none;
      background: transparent;
      font-size: 14px;
      border-radius: 24px;
      margin-bottom: 5px;
    }
    .chat-buttons {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0 16px;
      background-color: transparent;
      .chat-send-message-help {
        display: flex;
        width: 100%;
        .chat-send-message-help-hand,
        .chat-send-message-help-horn {
          width: 17px;
          height: 17px;
          margin-right: 20px;
          color: #5b5957;
          cursor: pointer;
          transition: transform 0.5s ease-out;
        }
        .chat-send-message-help-hand:hover,
        .chat-send-message-help-horn:hover {
          transform: scale(1.3);
        }
        .chat-send-message-help-hand:hover {
          color: #1070c7;
        }
        .chat-send-message-help-horn:hover {
          color: #e11f1f;
        }
      }
      .send {
        border: none;
        background: transparent;
        .sendarrowdisable,
        .sendarrow {
          color: #5b5957;
          font-size: 20px;
        }
        .sendarrow {
          color: #bd0909;
        }
      }
    }
  }

  .report-error {
    background-color: #bd0909;
    .chat-send-message-input {
      color: #fff;
    }
    .chat-send-message-input::placeholder {
      color: #f6f6f6;
    }
    .chat-buttons {
      .chat-send-message-help {
        .chat-send-message-help-hand,
        .chat-send-message-help-horn {
          color: #fff;
        }
        .chat-send-message-help-horn:hover {
          color: #fff;
        }
      }
      .send {
        .sendarrow {
          color: #fff;
        }
      }
    }
  }
</style>
