<template>
  <div class="ChatErrorMsg">
    <div class="ChatErrorMsg-title">
      <iconic name="precaution" class="view-chat"></iconic>
      <p class="title">Destacado</p>
    </div>
    <div class="ChatErrorMsg-description">
      <div class="ChatErrorMsg-description-image-des">
        <img :src="msg.fromUser.picture" alt="" />
        <div>
          <span>{{ msg.fromUser.user }}</span>
          <span class="hour">{{ getDate(msg.createdAt) }}</span>
        </div>
      </div>
      <span class="ChatErrorMsg-description-text">
        {{ msg.message }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["msg"],

    methods: {
      getDate(hour) {
        return new Date(hour).toLocaleTimeString("es-CO", { hour: "numeric", minute: "numeric" });
      },
    },
  };
</script>

<style lang="scss">
  .ChatErrorMsg {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5px;
    border-radius: 12px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    .ChatErrorMsg-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      color: #fff;
      background-color: #bd0909;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
      .title {
        font-size: 17px;
        margin-left: 5px;
      }
    }
    .ChatErrorMsg-description {
      padding: 10px;
      background-color: #f5dddd;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      .ChatErrorMsg-description-image-des {
        display: flex;
        width: 100%;
        margin-bottom: 5px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }
        span {
          display: block;
          font-size: 14px;
          color: #bd0909;
          font-weight: bold;
          line-height: 14px;
        }
        .hour {
          color: #5b5957;
          font-size: 11px;
        }
      }
    }
    .ChatErrorMsg-description-text {
      font-size: 14px;
      word-wrap: break-word;
    }
  }
</style>
