<script>
  export default {
    name: "CounterSlide",
    props: {
      targetDate: {
        type: String,
        required: true,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
      shouldAnimate: {
        type: Boolean,
      },
      showDaysText: {
        type: Boolean,
        default: true,
      },
      heightContainer: {
        type: Number,
        default: 18,
      },
      timeLeft: {
        type: Object,
        default: {
          value: 0,
          label: "Finalizado",
          secondLabel: "",
          secondValue: 0,
        },
      },
    },
    data() {
      return {
        timeOutId: null,
        delayedData: {
          value: 0,
          label: "Finalizado",
          secondLabel: "",
          secondValue: 0,
        },
        delayedDataTimeoutId: null,
        formatLabel: {
          Días: !this.isModal ? "DD" : "D",
          Horas: !this.isModal ? "HH" : "H",
          Minutos: !this.isModal ? "MM" : "M",
          Segundos: !this.isModal ? "SS" : "S",
          Finalizado: "Finalizado",
        },
      };
    },
    methods: {
      totalHeight(value) {
        return value * this.heightContainer;
      },
      finalTranslationY(value) {
        return this.totalHeight(value) - this.heightContainer;
      },
      formatNumber(number) {
        return number < 10 ? `0${number}` : `${number}`;
      },
      applyTransformation() {
        if (!this.$refs.numbersWrapper1 || !this.$refs.numbersWrapper2) {
          return;
        }

        const translationFirstNumber = this.finalTranslationY(this.timeLeft.value);
        const translationSecondNumber = this.finalTranslationY(this.timeLeft.secondValue);

        this.$refs.numbersWrapper1.style.transform = `translateY(-${translationFirstNumber}px)`;
        this.$refs.numbersWrapper2.style.transform = `translateY(-${translationSecondNumber}px)`;
      },
      initiateAnimation() {
        if (!this.shouldAnimate || !this.$refs.numbersWrapper1 || !this.$refs.numbersWrapper2) {
          return;
        }

        const transformStyle = `translateY(-0px)`;
        const transitionStyle = "transform 3s cubic-bezier(0.1, 0.7, 0.1, 1)";

        [this.$refs.numbersWrapper1, this.$refs.numbersWrapper2].forEach((wrapper) => {
          wrapper.style.transform = transformStyle;
          wrapper.style.transition = transitionStyle;
        });

        clearTimeout(this.timeOutId);
        this.timeOutId = setTimeout(() => {
          this.applyTransformation();
        }, 100);
      },
      moveNumber() {
        if (!this.$refs.numbersWrapper1 || !this.$refs.numbersWrapper2) {
          return;
        }

        const transitionStyle = "transform 0.5s ease-in-out";

        [this.$refs.numbersWrapper1, this.$refs.numbersWrapper2].forEach((wrapper) => {
          wrapper.style.transition = transitionStyle;
        });

        this.applyTransformation();
      },
    },
    watch: {
      timeLeft: {
        handler(newValue) {
          this.$nextTick(() => {
            this.moveNumber();
            clearTimeout(this.delayedDataTimeoutId);
            this.delayedDataTimeoutId = setTimeout(() => {
              this.delayedData = {
                value: newValue.value,
                label: newValue.label,
                secondLabel: newValue.secondLabel,
                secondValue: newValue.secondValue,
              };
            }, 500);
          });
        },
        immediate: true,
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initiateAnimation();
      });
    },
    beforeDestroy() {
      clearTimeout(this.timeOutId);
      clearTimeout(this.delayedDataTimeoutId);
    },
  };
</script>

<template>
  <div :class="['counterSlide', isModal && 'counterSlide--modal']">
    <div
      class="counterSlide__container"
      :style="{
        height: heightContainer + 'px',
        fontSize: heightContainer + 'px',
      }">
      <div class="counterSlide__numbers" ref="numbersWrapper1">
        <div class="counterSlide__number" v-for="n in delayedData.value" :key="n">
          {{ formatNumber(n) }}
          <span class="counterSlide__modalDayText" v-if="isModal && n === delayedData.value">{{ formatLabel[delayedData?.label] }}</span>
        </div>
      </div>
      <div class="counterSlide__separator" v-if="delayedData.value">:</div>
      <div class="counterSlide__numbers" ref="numbersWrapper2">
        <div class="counterSlide__number" v-for="n in delayedData.secondValue" :key="n">
          {{ formatNumber(n) }}
          <span class="counterSlide__modalDayText" v-if="isModal && n === delayedData.secondValue">{{ formatLabel[delayedData?.secondLabel] }}</span>
        </div>
      </div>
    </div>
    <div class="counterSlide__labelContainer" v-if="!isModal">
      <p :class="['counterSlide__dayText', !delayedData.value && 'counterSlide__dayText--onlyTxt']" v-if="showDaysText">{{ formatLabel[delayedData.label] }}</p>
      <p class="counterSlide__dayText" v-if="showDaysText && delayedData.secondLabel">{{ formatLabel[delayedData?.secondLabel] }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .counterSlide {
    position: relative;
    font-family: $newFont_medium;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-shadow: 0px 0px 1px #000000b6;
    &--modal {
      text-shadow: none;
    }
    &__labelContainer {
      @include Flex();
      gap: $space-8;
    }
    &__container {
      @include Flex(row, center, unset);
      overflow: hidden;
      line-height: 100%;
    }
    &__number {
      @include Flex();
    }
    &__dayText {
      width: 25%;
      font-size: 10px;
      line-height: 1.2;
      font-family: $newFont;
      font-style: italic;
      &--onlyTxt {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
</style>
