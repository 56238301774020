<template>
  <section class="filtersInLive">
    <div class="filtersInLive__filters">
      <div class="filtersInLive__filterSelect">
        <iconic class="filtersInLive__filterIcon" name="filter"></iconic>
        <select class="filtersInLive__filterItem" name="city" v-model="city" @change="executeFilter('city')">
          <option value="" selected>Ciudad</option>
          <option :value="city" v-for="(city, idx) in $cities" :key="city + idx">{{ city }}</option>
        </select>
      </div>
      <div class="filtersInLive__filterSelect">
        <iconic class="filtersInLive__filterIcon" name="filter"></iconic>
        <select class="filtersInLive__filterItem" name="office" :disabled="!city" v-model="office" @change="executeFilter('office')">
          <option value="" selected>Sede</option>
          <option :value="officeData.officeName" v-for="officeData in offices" :key="officeData._id">{{ officeData.officeName }}</option>
        </select>
      </div>
      <div class="filtersInLive__filterSelect">
        <iconic class="filtersInLive__filterIcon" name="filter"></iconic>
        <select class="filtersInLive__filterItem" name="room" :disabled="!office" v-model="room" @change="executeFilter">
          <option value="" selected>Habitación</option>
          <option :value="roomData.roomName" v-for="roomData in rooms" :key="roomData._id">{{ roomData.roomName }}</option>
        </select>
      </div>
      <div class="filtersInLive__filterSelect">
        <iconic class="filtersInLive__filterIcon" name="filter"></iconic>
        <select class="filtersInLive__filterItem" name="available" v-model="available" @change="executeFilter('available')">
          <option :value="available.value" v-for="(available, idx) in availableOptions" :key="idx">{{ available.text }}</option>
        </select>
      </div>
      <div class="filtersInLive__filtersClean" @click="executeFilter('reset')" v-if="showClean">
        <i class="fa fa-times" aria-hidden="true"></i>
        <p>Limpiar Filtros</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      city: "",
      office: "",
      room: "",
      available: "",
      platform: "",
      keysQuery: ["city", "office", "room", "available", "platform"],
      availableOptions: [
        { text: "Disponibilidad", value: "" },
        { text: "Disponible", value: "disponibles" },
        { text: "Ocupadas", value: "ocupadas" },
        { text: "Deshabilitada", value: "deshabilitadas" },
      ],
    };
  },
  computed: {
    query() {
      const query = {};
      this.keysQuery.forEach((key) => this[key] && (query[key] = this[key]));

      return query;
    },
    offices() {
      if (!this.city) {
        return [];
      }

      return this.$store.getters["offices/getData"].filter((x) => x?.cityName === this.city);
    },
    rooms() {
      if (!this.office) {
        return [];
      }

      return this.$store.getters["rooms/getData"].filter((x) => x?.officeName === this.office);
    },
    showClean() {
      return this.keysQuery.some((item) => this[item]);
    },
  },
  methods: {
    executeFilter(key) {
      const cleanByKey = {
        city: () => ["office", "room"].forEach((item) => (this[item] = "")),
        office: () => (this.room = ""),
        reset: () => this.keysQuery.forEach((item) => (this[item] = "")),
        available: () => (this.platform = ""),
        platform: () => (this.available = ""),
      };
      cleanByKey?.[key]?.();
      this.$router.push({ query: this.query }).catch(() => {});
    },
  },
  mounted() {
    const { query } = this.$route;
    this.keysQuery.forEach((key) => query[key] && (this[key] = query[key]));
  },
};
</script>

<style lang="scss">
.filtersInLive {
  @include Flex(column);
  gap: 10px;
  width: 100%;
  padding: $mpadding;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  &__filters {
    @include Flex(column);
    gap: 10px;
    width: 100%;
  }
  &__filtersClean {
    padding: 0 15px;
    font-size: 16px;
    font-weight: 600;
    color: $primary-color;
    cursor: pointer;
    width: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      width: 120px;
    }
  }
  &__filterSelect {
    font-size: 16px;
    position: relative;
    width: 100%;
  }
  &__filterIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto;
  }
  &__filterItem {
    width: 100%;
    height: 36px;
    padding-left: $mpadding * 2.5;
    appearance: none;
    text-align: start;
    text-transform: capitalize;
    outline: none;
    color: $black;
    background: #f0f0f0;
    box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.41);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  @media screen and (min-width: $tablet-width) {
    &__filters {
      @include Flex(row, space-between);
    }
  }
}
</style>
