<script>
  import { socket } from "@/socket";
  import InputComment from "../reactions/Comments/InputComment.vue";
  import CommentItem from "../reactions/Comments/CommentItem.vue";
  import Likes from "../reactions/likes/Likes.vue";
  import { scrollBottom } from "@/js/utils";
  export default {
    props: ["comments", "contest", "totalViewers", "showComments"],
    components: {
      InputComment,
      CommentItem,
      Likes,
    },
    data() {
      return {
        parentCommentId: null,
        inputPlaceholder: null,
        isResponse: false,
      };
    },
    computed: {
      IsAdmin() {
        return this.$userData.role === "superadmin";
      },
      payload() {
        return { item: this.contest._id, component: "Competition" };
      },
      lastComments() {
        return this.comments.slice(0, 3);
      },
      commentsFiltered() {
        return this.comments.filter((c) => !c.parentComment);
      },
    },
    methods: {
      showUsersView(mode) {
        this.$emit("openVisor", mode ? this.contest._id : "");
      },
      setParentCommentId(comment) {
        this.parentCommentId = comment._id;
        this.isResponse = true;
        document.querySelector(".commentList").focus();
        this.inputPlaceholder = `Respondiendo a ${comment.user.artisticName || comment.user.user}`;
      },
      scrollSmooth() {
        if (this.parentCommentId) return;
        const options = { behavior: "smooth" };
        scrollBottom(document.documentElement, options);
      },
      resetResponseData() {
        this.parentCommentId = null;
        this.inputPlaceholder = null;
        this.isResponse = false;
      },
      sendComment(comment) {
        socket.emit("newCommentItem", { comment, item: this.contest._id, component: "Competition", parentCommentId: this.parentCommentId });
        this.scrollSmooth();
        this.resetResponseData();
      },
      openLikes(dataLikers = []) {
        if (!dataLikers.length) {
          return;
        }
        const text = "Los usuarios que dieron like:";
        const likers = dataLikers.map(({ user }) => user);
        const listUsers = { likers, text };
        this.$emit("listOfLikes", listUsers);
      },
      pinMessage(comment) {
        if (!this.IsAdmin) {
          return;
        }
        socket.emit("togglePinComment", { commentId: comment._id, isPinned: !comment.isPinned ? Date.now() : null, component: "Competition" });
      },
      onSocketResponse() {
        socket.on("newComment", (payload) => {
          const { newComment, component } = payload;
          const execByComponent = {
            Competition: (data) => {
              if (data.commentedItem === this.$route.params.id) {
                if (newComment.isPinned) {
                  this.comments.unshift(newComment);
                } else {
                  this.comments.push(newComment);
                }
              }
            },
          };
          execByComponent?.[component]?.(newComment);
        });

        socket.on("togglePinComment", (data) => {
          const { _id, isPinned, createdAt } = data.comment;

          const findCommentIndex = this.comments.findIndex((comment) => comment._id === _id);
          if (isPinned) {
            if (findCommentIndex === -1) {
              this.comments.unshift(data.comment);
            } else {
              this.comments.splice(findCommentIndex, 1);
              this.comments.unshift(data.comment);
            }
            return;
          }
          if (findCommentIndex === -1) {
            return;
          }

          this.comments.splice(findCommentIndex, 1);
          const newIndex = this.comments.findIndex((comment) => !comment.isPinned && new Date(comment.createdAt) > new Date(createdAt));

          if (newIndex === -1) {
            this.comments.push(data.comment);
          } else {
            this.comments.splice(newIndex, 0, data.comment);
          }
        });
      },
    },
    mounted() {
      this.onSocketResponse();
    },
    beforeDestroy() {
      socket.off("newComment");
    },
  };
</script>

<template>
  <div class="commentsList" ref="commentsContainer">
    <div class="commentsList__header">
      <h2 class="commentsList__title">Descubre lo que opinan</h2>
      <div class="commentsList__reactions">
        <div @click="showUsersView(true)" class="commentsList__eyeContainer" v-if="IsAdmin && totalViewers">
          <iconic name="eye_contets" class="commentsList__eye" />
          <span class="commentsList__number">{{ totalViewers }}</span>
        </div>
        <div class="commentsList__avatarsContainer" v-if="lastComments.length > 0">
          <Avatar class="commentsList__avatarList" v-for="(user, idx) in lastComments" :key="idx" :user="user.user"> </Avatar>
        </div>
        <span class="commentsList__total">{{ comments.length }} </span>
        <Likes :likes="contest.likers || []" :showLikes="true" :payload="payload" :openLikes="() => openLikes(contest.likers)" />
      </div>
    </div>
    <div class="commentsList__container">
      <div class="commentsList__comments" v-if="comments.length">
        <CommentItem
          v-for="item in commentsFiltered"
          :key="item._id"
          :comment="item"
          :canPinMessage="IsAdmin"
          :allComments="comments"
          @pinMessage="pinMessage"
          @openLikes="openLikes"
          @setParentCommentId="setParentCommentId" />
      </div>
      <div class="commentsList__input" v-if="showComments">
        <InputComment @textInput="sendComment" :placeholder="inputPlaceholder" className="commentList" position="top: -320px" :isResponse="isResponse" @resetResponse="resetResponseData" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .commentsList {
    @include Flex(column, space-between);
    width: 100%;
    height: 100%;
    gap: $space-4;
    border-radius: $radius-16;
    position: relative;
    &__header {
      @include Flex(column, space-between, flex-start);
      width: 100%;
    }
    &__avatarsContainer {
      @include Row(flex-start, center);
      border-radius: 12px;
      border: solid 1px #fff;
      background: #fff;
    }
    &__title {
      font-family: $newFont_semiBold;
      text-transform: unset;
    }
    &__avatarList {
      width: 26px;
      height: 26px;
      flex-shrink: 0;
      z-index: 2;

      &:first-child {
        margin-left: 0;
        z-index: 1;
      }

      &:last-child {
        margin-left: -9px;
        z-index: 0;
      }

      &:nth-child(2) {
        margin-left: -9px;
        z-index: 1;
      }
    }
    &__reactions {
      @include Flex(row, center, center);
      gap: 10px;
    }
    &__eyeContainer {
      @include Flex(row, center, center);
      gap: $space-4;
    }
    &__eye {
      font-size: 26px;
      transition: 0.3s ease-in-out;
      padding: $space-4;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.06);
        border-radius: $radius-8;
      }
    }

    &__number {
      font-size: 16px;
      font-family: $newFont_regular;
    }

    &__total {
      font-family: $newFont_regular;
      z-index: 2;
    }

    &__input {
      @include Flex(row, flex-start, center);
      position: sticky;
      left: 0;
      bottom: 74px;
      z-index: 10;
      height: 70px;
      background: linear-gradient(360deg, #ffffff 71.87%, rgba(255, 255, 255, 0.52) 88.84%);
      padding-bottom: 5px;
      width: 100%;
    }
    .InputComment {
      bottom: 0px;
      width: calc(100% - 60px);
    }

    &__container {
      @include Flex(column, space-between, flex-start);
      position: relative;
      height: 86%;
      width: 100%;
    }
    &__comments {
      @include Flex(column, flex-start, flex-start);
      width: 100%;
      height: 80%;
      gap: $space-16;
      padding: $space-8 $space-0;
    }
    @media (min-width: 768px) {
      .InputComment {
        width: calc(100% - 100px);
      }
      &__input {
        bottom: 0px;
        height: 100px;
      }
      &__header {
        @include Flex(row, space-between, center);
      }
    }
    @media (min-width: 1440px) {
      .InputComment {
        width: calc(100% - 70px);
      }
    }
    @media (min-width: 1600px) {
      .InputComment {
        width: 100%;
      }
    }
  }
</style>
