<script>
  import { mapActions } from "vuex";
  import { initSocket } from "@/socket";
  import Header from "../common/Header/Header.vue";
  import Navbar from "../common/Header/Navbar/Navbar.vue";
  import AutoHeader from "@/components/header/AutoHeader.vue";
  import ModalModels from "@/components/alert/ModalModels.vue";
  import ViewsController from "./ViewsController.vue";
  import SocketSessions from "../socket/sessions";
  import SocketMySpace1A from "../socket/myspace";
  import updSession from "@/common/UpdSession";
  import MainMenuList from "../components/widgets/MainMenu.js";
  import HeaderMenu from "@/common/Header/HeaderMenu.vue";
  import ContainerView from "@/common/ContainerView/ContainerView.vue";

  export default {
    components: {
      Header,
      Navbar,
      ViewsController,
      AutoHeader,
      ModalModels,
      HeaderMenu,
      ContainerView,
    },
    data() {
      return {
        notify: [],
        socket: null,
        brand: { name: "My Space", icon: "myspace" },
      };
    },
    computed: {
      viewLastPath: function () {
        const route = this.$route.path;
        if (route) {
          return route.split("/").length > 1 ? route.split("/")[1] : null;
        }
      },
      menus() {
        return MainMenuList.menus.filter((item) => {
          if (item.icon === "users") {
            item.name = this.user.role === "monitor" ? "Modelos" : "Usuarios";
          }
          return item.roles.includes(this.user.role);
        });
      },
      user() {
        return this.$userData;
      },
      socketIsReady() {
        return this.socket;
      },
      screen() {
        return this.$store.state.screen.width;
      },
    },
    methods: {
      ...mapActions("polls", ["getPoll"]),
      ...mapActions("competitions", ["updatePaLikes"]),
      ...mapActions("users", ["fetchUsers", "fetchMonitors"]),
      ...mapActions("cities", { fetchCities: "fetch" }),
      ...mapActions("offices", { fetchOffices: "fetch" }),
      ...mapActions("rooms", { fetchRooms: "fetch" }),
      ...mapActions("applications", { getApps: "getApps" }),
      ...mapActions("platforms", ["closePlatform", "getIdProcess"]),
      async lisentVisibility() {
        const state = document.visibilityState === "visible";
        const socketS = SocketSessions.getSocket();
        if (state) {
          await this.$store.dispatch("connectedUsers/initUsers");
        }
        if (!socketS) {
          return;
        }

        socketS.emit("isActive", { isActive: state });
        this.$store.commit("user/updateUserInWindow", state);
      },
      getAuthSocket() {
        const tokenName = process.env.VUE_APP_TOKEN_NAME;
        const token = localStorage.getItem(tokenName);

        return { [tokenName]: token };
      },
      initilizeSocketMySpace() {
        const { _id = "", SSID = "", role = "", area = "", city = "" } = this.user;
        const query = `_id=${_id}&SSID=${SSID}&role=${role}&area=${area}&city=${city}`;
        const auth = this.getAuthSocket();
        const socketMySpace = SocketMySpace1A.connect({ query, auth });

        socketMySpace.on("connect", () => {
          console.log("Cliente conectado al servidor de MySpace1A.");
        });
        socketMySpace.on("newView", (payload) => {
          const { newView, component } = payload;
          const execByComponent = {
            MonthEmployee: (data) => this.$store.commit("MonthEmployee/setNewViewToEmployee", data),
          };

          execByComponent?.[component]?.(newView);
        });
        socketMySpace.on("newLike", (payload) => {
          const { newLike, component } = payload;
          const execByComponent = {
            monthemployees: (data) => this.$store.commit("MonthEmployee/setNewLikeToEmployee", data),
          };

          execByComponent?.[component]?.(newLike);
        });
        socketMySpace.on("newClap", (payload) => {
          const { newClap, component } = payload;
          const execByComponent = {
            monthemployees: (data) => this.$store.commit("MonthEmployee/setNewClapToEmployee", data),
          };

          execByComponent?.[component]?.(newClap);
        });
      },
      initilizeSocketSessions() {
        const { _id = "", SSID = "" } = this.user;
        const query = `_id=${_id}&SSID=${SSID}`;
        const auth = this.getAuthSocket();
        const socketS = SocketSessions.connect({ query, auth });

        socketS.on("connect", () => {
          console.log("Cliente conectado al servidor de sesiones.");

          this.lisentVisibility();
        });
        socketS.on("expiredSession", () => {
          this.$store.dispatch("user/logoutClient");
          window.location.href = "/sesionwrong";
        });
        socketS.on("closeApp", (app) => {
          this.closePlatform(app);
        });
        socketS.on("verifyConnection", () => socketS.emit("isAvailable"));
        socketS.on("userUpdated", (user) => this.$store.commit("connectedUsers/updateUser", user));
      },
      initEvents() {
        const _this = this;
        this.socket.on("newLike", (payload) => {
          const { newLike, component } = payload;
          const execByComponent = {
            monthemployees: (data) => this.$store.commit("MonthEmployee/setNewLikeToEmployee", data),
            Competition: (data) => this.$store.commit("competitions/setNewLikeToContest", data),
            commentsContest: (data) => this.$store.commit("competitions/setNewLikeToCommentContest", data),
          };

          execByComponent?.[component]?.(newLike);
        });
        this.socket.on("error", (err) => console.log(err));
        this.socket.on("usersSessions", (usersSessions) => {
          this.$store.commit("connectedUsers/setUsersSpark", usersSessions);
        });
        this.socket.on("userUpdated", async ({ user, event, emitter }) => {
          if (emitter !== this.$userData._id && user?.monitorId === this.$userData._id) {
            if (event === "getOutModelTemp") this.$root.$emit("modelFree");
          }
          this.$store.commit("connectedUsers/updateUser", user);
        });
        this.socket.on("saveNextReservHour", async ({ hour }) => {
          localStorage.removeItem("nextReservHour");
          const ipAllowed = localStorage.getItem("ipAllowed");

          if (!ipAllowed) {
            return;
          }

          this.$store.dispatch("user/couterNextReserv", hour);
        });
        this.socket.on("userDisconnected", (userDisconnected) => this.$store.commit("connectedUsers/disconnect", userDisconnected));
        this.socket.on("modelBreak", (data) => _this.$socket.modelBreak(data, this.user, _this));
        this.socket.on("newNotification", async (data) => {
          if (data?.type === "login") {
            const model = this.$store.getters["connectedUsers/getUserById"](data.user._id);
            if (model?.monitorId === this.$userData._id) this.$root.$emit("modelFree");
            if (data?.user?._id === this.$userData._id) {
              localStorage.setItem("getModels", "yes");
              this.$root.$emit("modelFree");
            }
          }
          await this.createNotification(data);
        });
        this.socket.on("restartGoal", ({ goalId }) => this.$store.commit("goals/restartGoal", goalId));
        this.socket.on("updateModelGoal", ({ emitter, updatedModel }) => {
          if (emitter === this.user._id) return;
          this.$store.commit("goals/updateModelGoals", updatedModel);
        });
        this.socket.on("modelAssigned", ({ modelArtisticName, coorMonitor }) => {
          const message = `${coorMonitor} te asignó la modelo ${modelArtisticName}`;
          new Notification(this.user.name, {
            icon: "./img/icons/apple-touch-icon-76x76.png",
            body: message,
            requireInteraction: true,
          });
        });
        this.socket.on("likeNews", (data) => {
          this.$store.commit("news/updateLikes", {
            like: data,
            userId: this.$userToken._id,
          });
        });
        this.socket.on("likeParticipants", (data) => {
          this.updatePaLikes({ like: data, userId: this.$userToken._id });
        });
        document.addEventListener("visibilitychange", this.lisentVisibility);
      },
      async createNotification(data) {
        try {
          const notifyIsOpen = this.$store.state.user.activeNotification;
          if (notifyIsOpen) return;
          const exists = this.notify.some((notify) => notify?.typeNotifications === data?.notify?.typeNotifications && notify?.userName === data?.notify?.userName);
          if (this.notify.length < 2) {
            if (!exists) this.notify = [...this.notify, data?.notify];
          } else {
            if (!exists) {
              this.notify = [...this.notify, data?.notify];
              if (this.notify.length >= 2) this.notify.shift();
            }
          }
          this.$store.state.user.accountant = this.$store.state.user.accountant + 1;
          localStorage.setItem("acount", this.$store.state.user.accountant);
        } catch (error) {
          return false;
        }
      },
      async verifyPendingHelpdesk() {
        try {
          const title = "Mesa de ayuda";
          const body = "Tienes mensajes sin leer";

          parent.postMessage({ message: "notification", data: { title, body } }, "*");

          if (Notification.permission !== "granted") {
            return;
          }
          const { data } = await this.$axios.get(`/helpdesk/hasunreadmessages`);
          const { error, hasUnread } = data;

          if (!error && hasUnread) {
            const notification = new Notification(title, { body });

            notification.addEventListener("click", () => {
              this.$router.push({ name: "helpdesk" });
              notification.close();
            });
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      async getPolls(userId) {
        const data = await this.getPoll(`status=active&voter=${userId}`);
        this.$store.commit("polls/setPollsToResponse", data);
      },
      setSocket(param) {
        this.$store.commit("connectedUsers/initSocket");
        this.socket = param;
      },
    },
    async beforeMount() {
      try {
        this.app = this.$route.query.app;
        await this.getApps();
        await this.fetchUsers();
        await this.fetchMonitors();
        this.$store.dispatch("connectedUsers/initUsers");
        this.fetchCities();
        this.fetchOffices();
        this.fetchRooms();
        this.getIdProcess();

        if (this.user.role === "superadmin") this.$store.dispatch("connectedUsers/initUsersSpark");
      } catch (error) {
        if (error.message === "Request aborted") console.log("Se está refrescando demasiado rápido");
      }
    },
    async mounted() {
      updSession();
      const { _id, role, room } = this.user;

      this.verifyPendingHelpdesk();
      this.getPolls(_id);

      if (role === "model" && room === "Satelite") {
        this.$store.dispatch("user/validateAssignmet");
      }

      await initSocket(this.user, this.setSocket);

      if (this.socketIsReady) {
        this.initEvents();
      }

      this.initilizeSocketMySpace();
      this.initilizeSocketSessions();

      try {
        if (Notification.permission !== "granted") {
          Notification.requestPermission();
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  };
</script>

<template>
  <section class="main" v-if="user">
    <Header :brand="brand" :menus="menus">
      <HeaderMenu>
        <AutoHeader />
      </HeaderMenu>
    </Header>
    <Navbar :menus="menus" v-if="screen < 768" :showName="true" />

    <ContainerView>
      <div :class="`content ${viewLastPath}`">
        <ModalModels v-if="user.role === 'monitor' || user.isSpecialFunctions" />
        <router-view />
      </div>
    </ContainerView>

    <ViewsController />
  </section>
</template>

<style lang="scss">
  .content {
    @include Flex();
    width: 100%;
    height: 100%;
  }
</style>
