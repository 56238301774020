import Vue from "vue";
import Vuex from "vuex";
import user from "./user.js";
import news from "./news.js";
import goals from "./goals";
import downloads from "./downloads";
import connectedUsers from "./connectedUsers.js";
import axios from "@/api/axios";
import restVuex from "@/store/base/restVuex";
import newsCategories from "./modulesNewsCategories/newsCategories";
import helpdesk from "./helpdesk.js";
import applications from "./Applications.js";
import policies from "./Policies.js";
import chat from "./chat.js";
import ManagementPermission from "./ManagementPermission.js";
import Occupation from "./Occupation.js";
import polls from "./polls.js";
import competitions from "./Competitions.js";
import comments from "./commentsContest.js";
import MonthEmployee from "./MonthEmployee.js";
import platforms from "./platforms.js";
import skipNewsHome from "./skipNewsHome.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users: restVuex("users"),
    cities: restVuex("cities"),
    offices: restVuex("offices"),
    rooms: restVuex("rooms"),
    news,
    user,
    goals,
    connectedUsers,
    downloads,
    newsCategories,
    helpdesk,
    chat,
    applications,
    ManagementPermission,
    policies,
    Occupation,
    polls,
    competitions,
    comments,
    MonthEmployee,
    platforms,
    skipNewsHome,
  },
  state: () => ({
    isDesktopApp: localStorage.getItem("isDesktopApp") || false,
    hasUpdateSW: false,
    screen: { width: window.innerWidth, height: window.innerHeight, scrollTop: 0, scrollDirection: "down" },
    scrollKey: 0,
    appMenu: localStorage.getItem("appMenu") || "",
    appMenuMobile: null,
    countCases: 0,
    localIPs: [],
    companies: []
  }),
  actions: {
    initEvents({ state }) {
      window.removeEventListener("scroll", isScrolling, true);
      window.addEventListener("scroll", isScrolling, true);

      let setScrollBuffer = null;

      function isScrolling() {
        const scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (scrolledToBottom) {
          const elms = document.querySelectorAll(".click-on-bottom");
          elms.forEach((el) => el.click());
        }

        const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        clearTimeout(setScrollBuffer);

        setScrollBuffer = setTimeout(() => {
          if (windowScrollTop > state.screen.scrollTop) {
            state.screen.scrollDirection = "down";
          } else {
            state.screen.scrollDirection = "up";
          }

          state.screen.scrollTop = windowScrollTop;
          state.scrollKey++;
        }, 100);
      }

      //postMessage
      function onMessage(evt) {
        if (evt.data.msg === "updateSW") {
          window.caches
            .keys()
            .then(function(names) {
              for (let name of names) caches.delete(name);
            })
            .catch((error) => {
              console.log("PopUpdateSW", error);
            });
          document.querySelector(".filterBlur")?.classList.add("blur");
          state.hasUpdateSW = true;
        }

        if (evt.data.message === "isDesktopApp") {
          localStorage.setItem("isDesktopApp", "true");
          state.isDesktopApp = true;
          state.user.localIPs = evt.data.localIPs;
        }
      }

      window.removeEventListener("message", onMessage);
      window.addEventListener("message", onMessage);
      window.addEventListener("resize", function(e) {
        let width = window.innerWidth;
        let height = window.innerHeight;
        state.screen.width = width;
        state.screen.height = height;
      });
    },
    async getCases({ state }) {
      if (localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)) {
        const { data } = await axios.get(`/helpdesk/number`);
        state.countCases = data?.total || 0;
      }
    },
    async setItemViewed(_, item) {
      try {
        await this.$axios.post(`views?item=${item}`);
      } catch (error) {
        console.log(error);
      }
    },
    async getItemViewersTotal(_, item) {
      try {
        const { data } = await this.$axios.get(`views/totals?item=${item}`);

        return data?.totalViewers || 0;
      } catch (error) {
        console.log(error);
      }
    },
    async getItemViewers(_, { item, userId = "", page = "" }) {
      try {
        const { data } = await this.$axios.get(`views?item=${item}&page=${page}&userId=${userId}`);
        const { viewers = [], hasMore = false, error } = data;

        if (!error) {
          return data;
        }

        return { viewers, hasMore };
      } catch (error) {
        console.log(error);
      }
    },
    async getCompanies({ commit }) {
      try {
        const { data } = await this.$axios("/company?status=active");    
            
        commit("setCompanies", data?.companies?.companies);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
  },
  mutations: {
    setCompanies(state, payload = []) {     
      state.companies = payload;
    },
  },
  getters: {
    screen: (state) => state.screen,
    listCompanies: (state) => state.companies,
  },
});
