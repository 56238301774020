<script>
  import { mapGetters } from "vuex";
  import globals from "@/js/global.js";

  export default {
    props: {
      user: Object,
      isListMonitor: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters("user", ["userCanUseBot"]),
      ...mapGetters("platforms", ["canAttemptOpen"]),
      canAddRespaldo() {
        return !this.userCanUseBot || this.canAttemptOpen;
      },
      state() {
        let { isActive, isOnline } = this.user;
        return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
      },
      monitorAssigned() {
        let { monitorIdTemp, monitorId } = this.user;
        if (!monitorIdTemp) return;

        const isOfficial = `${monitorId === monitorIdTemp ? "Oficial" : "Temporal"}`;
        if (this.isListMonitor) return isOfficial;

        let monitors = this.$store.getters["connectedUsers/getMonitors"];
        let monitorAssigned = monitors.find((item) => item._id === monitorIdTemp);
        return monitorAssigned && monitorAssigned.user + " - " + isOfficial;
      },
      usersToAssign() {
        const { modelEmail, monitorEmail } = this.$store.state.user.usersToAssign;
        return { modelEmail, monitorEmail };
      },
      propsMenu() {
        const { artisticName = "", email = "", streamateUser, streamatePassword, jasminUser, jasminPassword } = this.user;

        const propsMenu = {
          title: "",
          options: [],
        };

        const addOption = (name, icon, func, param, style, link) => {
          propsMenu.options.push({
            name,
            icon,
            func,
            param,
            style,
            link,
          });
        };

        if (jasminUser) {
          addOption("Live Jasmin", "transmission", "openLink", [`https://www.livejasmin.com/en/chat/${artisticName.replace(/\s+/g, "")}`], "jasmin");

          if (this.userCanUseBot) {
            addOption("Modelcenter", "user", "authModelcenter", [{ jasminUser, jasminPassword }], "jasmin", "https://staticx1.dditscdn.com/msc/jasmin/5afe5df7283c72ee0d9d.svg");
          }
          if (this.canAddRespaldo) {
            addOption(
              this.getNamePlatform("Modelcenter", this.userCanUseBot),
              "user",
              "openAutoLogin",
              [`/downloadfilevbsweb?model=${email}`, "jasminweb"],
              "jasmin",
              "https://staticx1.dditscdn.com/msc/jasmin/5afe5df7283c72ee0d9d.svg"
            );
          }
        }

        if (streamateUser) {
          addOption("Live Streamate", "transmission", "openLink", [`https://www.streamate.com/cam/${artisticName.replace(/\s+/g, "")}`], "streamate");

          if (this.userCanUseBot) {
            addOption("Streamate Model", "user", "authStreamate", [{ streamateUser, streamatePassword }], "streamate", "https://www.streamatemodels.com/img/logo192.png");
          }

          if (this.canAddRespaldo) {
            addOption(
              this.getNamePlatform("Streamate Model", this.userCanUseBot),
              "user",
              "openAutoLogin",
              [`/downloadfilevbsstreamate?model=${email}`, "streamate"],
              "streamate",
              "src/assets/img/str.png"
            );
          }
        }

        return propsMenu;
      },
      officeConnected() {
        let { roomTemp, office } = this.user;
        return roomTemp?.officeName || office;
      },
      roomConnected() {
        let { roomTemp, room } = this.user;
        return roomTemp?.roomName || room;
      },
    },
    watch: {
      usersToAssign() {
        let { modelEmail, monitorEmail } = this.usersToAssign;
        if (!monitorEmail) return;
        this.assignMonitor(modelEmail, monitorEmail);
      },
    },
    methods: {
      getNamePlatform(name, cantUse) {
        return !cantUse ? name : "Respaldo";
      },
      setUserProfile(user) {
        if (this.monitorAssigned) user.monitorAssigned = this.monitorAssigned;
        this.$store.state.user.userProfile = user;
      },
      checkAssign(modelEmail) {
        if (this.$userData.role === "monitor") {
          return this.assignMonitor(modelEmail, this.$userData.email);
        }
        this.$store.state.user.usersToAssign = { modelEmail, monitorEmail: "" };
      },
      assignMonitor(modelEmail, monitorEmail) {
        this.$axios.post(`/operation`, {
          modelEmail,
          monitorEmail,
        });
        this.$store.state.user.usersToAssign = {};
      },
      unassignMonitor(modelEmail) {
        this.$axios.post(`/operation/unassign`, {
          modelEmail,
        });
      },
      finishStreaming(model) {
        this.$store.state.user.ModelFinish = model;
      },
      menuAssign: function (propsMenu, evt) {
        propsMenu.event = evt;
        this.$store.state.user.menuAssign = propsMenu;
      },
      canAssignme: function (model) {
        return globals.canAccessToModel(this.$userData, model);
      },
    },
  };
</script>

<template>
  <div class="modelCard">
    <div class="modelCard__wrapper">
      <div class="modelCard__avatarContainer tool-tip" :stateAvatar="state" @click="setUserProfile(user)">
        <Avatar class="modelCard__avatar" :user="user" />
        <span class="modelCard__statusCircle" :stateAvatar="state"></span>
        <span class="tooltiptext tool-auto">Perfil</span>
      </div>
      <div class="modelCard__userData">
        <span class="modelCard__name">{{ user.artisticName }}</span>
        <div class="modelCard__info">
          <span>{{ officeConnected }}</span>
          <span v-if="roomConnected">{{ " - " + roomConnected }}</span>
          <span v-if="monitorAssigned">{{ " - " + monitorAssigned }}</span>
        </div>
      </div>
      <div class="modelCard__buttonContainer">
        <button class="modelCard__button tool-tip" v-if="!monitorAssigned && canAssignme(user)" @click="checkAssign(user.email)">
          <span class="tooltiptext tool-auto">Asignar</span>
          +
        </button>
        <div class="modelCard__actionButtons" v-else-if="monitorAssigned && isListMonitor && (['superadmin', 'coordinator'].includes($userData.role) || $userData._id === user.monitorIdTemp)">
          <button class="modelCard__buttonAct tool-tip" v-if="user.isStreaming" @click="finishStreaming(user)">
            <span class="tooltiptext tool-auto">Desconexión</span>
            <iconic class="modelCard__icon" name="disconnect" />
          </button>
          <button class="modelCard__buttonAct tool-tip" @click="unassignMonitor(user.email)">
            <span class="tooltiptext tool-auto">Liberar</span>
            <iconic class="modelCard__icon" name="release" />
          </button>
          <button class="modelCard__buttonAct tool-tip" @click="menuAssign(propsMenu, $event)">
            <span class="tooltiptext tool-auto">Opciones</span>
            <iconic class="modelCard__icon" name="ellipsis_v" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .modelCard {
    .tooltiptext {
      padding: 10px;
      font-size: 14px;
      font-weight: bold;
    }
    &__wrapper {
      @include Flex(row, space-between);
      flex-wrap: wrap;
      padding: 5px 15px;
      background: #f3f3f3;
      border-radius: 27px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        background: rgba(242, 189, 189, 0.15);
        transition: 0.3s ease-in-out;
      }
    }
    &__avatarContainer {
      position: relative;
      border: 2px solid $alto;
      border-radius: 50%;
      cursor: pointer;
      &[stateAvatar="online"] {
        border-color: $la_palma;
      }
      &[stateAvatar="away"] {
        border-color: $tree_Poppy;
      }
    }
    &__avatar {
      width: 37px;
      height: 37px;
      border: 1px solid $white;
    }
    &__statusCircle {
      position: absolute;
      width: 10px;
      height: 10px;
      right: 1px;
      bottom: 1px;
      border: 1px solid $white;
      border-radius: 50%;
      background-color: $alto;
      &[stateAvatar="online"] {
        background-color: $la_palma;
      }
      &[stateAvatar="away"] {
        background-color: $tree_Poppy;
      }
    }
    &__avatarContainer,
    &__buttonContainer {
      width: fit-content;
    }
    &__userData {
      width: 100%;
      width: -moz-available; /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      width: stretch;
      margin: 0 $mpadding;
      max-width: 142px;
      margin: 0 $mpadding;
      text-overflow: ellipsis;
      overflow: auto;
    }
    &__name {
      max-width: 116px;
      font-size: 1em;
      color: black;
      text-overflow: ellipsis;
    }
    &__info {
      font-size: 0.9em;
      color: $chicago;
    }
    &__buttonContainer {
      display: flex;
      min-width: 33px;
    }
    &__buttonAct,
    &__button {
      width: 33px;
      height: 33px;
      font-size: 33px;
      line-height: 4px;
      color: white;
      background-color: $primary-color;
      border: none;
      border-radius: 50%;
    }
    &__actionButtons {
      display: flex;
    }
    &__buttonAct {
      margin-left: 5px;
      background-color: transparent;
      color: black;
      &:hover {
        color: white;
        background-color: $primary-color;
        transition: 0.3s ease-in-out;
      }
    }
    &__icon {
      font-size: 20px;
      margin: 0 5px;
    }
    @media screen and (min-width: $tablet-width) {
      &__userData {
        max-width: 100%;
      }
      &__wrapper {
        flex-wrap: nowrap;
      }
    }
  }
</style>
