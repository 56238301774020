<script>
  import { socket } from "@/socket";

  export default {
    props: ["likes", "showLikes", "payload", "openLikes"],
    computed: {
      isLiked() {
        return this.likes.some((like) => like.user._id === this.$user._id);
      },
    },
    methods: {
      setLiked() {        
        socket.emit("setLikeItem", this.payload);
      },
      showLikesMethod() {
        if (!this.openLikes) {
          return;
        }

        this.openLikes(this.likes);
      },
    },
  };
</script>

<template>
  <section class="likes">
    <div class="likes__heartWrapper" @click.stop="setLiked">
      <div :class="['likes__heart', isLiked && 'likes__heart--liked']" />
    </div>
    <p :class="['likes__total', openLikes && 'likes__total--method']" @click.stop="showLikesMethod" v-if="showLikes">{{ likes.length }}</p>
  </section>
</template>

<style lang="scss" scoped>
  .likes {
    @include Flex(row, space-between);
    color: #000;
    &__heartWrapper {
      @include Flex(row);
      height: 40px;
      width: 30px;
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    &__heart {
      height: 70px;
      width: 70px;
      background: url("../../../assets/img/heart_black.png");
      position: absolute;
      background-position: left;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      z-index: 1;
      &--liked {
        animation: like-anim 0.7s steps(28) forwards;
      }
    }
    &__total {
      padding: 0 $space-8;
      font-family: $newFont-regular;
      background: transparent;
      z-index: 5;
      &--method {
        cursor: pointer;
      }
    }
    @keyframes like-anim {
      to {
        background-position: right;
      }
    }
  }
</style>
