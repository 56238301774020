<template>
  <div ref="chatMessages" class="chat-messages-container">
    <div class="unread-msg" v-if="unReadmessage.length">
      <span> {{ unReadmessage.length }} Mensajes nuevos </span>
    </div>
    <PopUpSendHelpModel v-if="popupSendModels" />

    <PopUpViewMessagesByUsers v-if="popUpViewMessagesByUsers" :views="views" />

    <div v-for="msg in messages" :id="msg._id" :key="msg.id" :class="msg.usersView.some(({ _id }) => profileUser._id === _id) ? 'own-messages' : 'unread-messages'">
      <div :class="profileUser._id === msg.fromUser._id ? 'own-chat-message' : 'chat-message'" v-if="msg.message && !hornMessage(msg) && !helpMessage(msg)">
        <div class="message-text">{{ msg.message }}</div>
        <img class="msg-image" :src="msg.fromUser.picture" :alt="msg.fromUser.picture" />
      </div>
      <ChatErrorMsg :msg="msg" v-if="hornMessage(msg)" />
      <ChatHelpModelsVue :msg="msg" v-if="helpMessage(msg)" />

      <div :class="profileUser._id === msg.fromUser._id ? 'own-messages-date' : 'message-date'">
        <span>{{ msg.fromUser.user }},</span>
        <span>{{ getDate(msg.createdAt) }}</span>
        <div v-if="profileUser.role === 'superadmin'" @click="messagesViewByUsers(msg._id)" class="view-users-by-messages">
          <iconic name="viewchat" class="view-chat"></iconic>
          <span>{{ msg.usersView.length }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    components: {
      ChatHelpModelsVue: () => import("./ChatHelpModels.vue"),
      ChatErrorMsg: () => import("./ChatErrorMsg.vue"),
      PopUpSendHelpModel: () => import("./PopUpSendHelpModel.vue"),
      PopUpViewMessagesByUsers: () => import("./PopUpViewMessagesByUsers.vue"),
    },
    data() {
      return {
        messagesWithUsers: [],
        usersViews: "",
      };
    },
    computed: {
      ...mapGetters("chat", {
        unReadmessage: "unReadMessages",
      }),
      popupSendModels() {
        return this.$store.state.chat.popupSendModels;
      },
      popUpViewMessagesByUsers() {
        return this.$store.state.chat.popUpViewMessagesByUsers;
      },
      messages() {
        return this.$store.state.chat.messagesChat;
      },
      profileUser() {
        return this.$store.state.user.profile;
      },
      hornMessage() {
        return function (msg) {
          return msg.errorMsg;
        };
      },
      helpMessage() {
        return function (msg) {
          return msg.currentModels && msg.currentModels.length;
        };
      },
      views() {
        return this.messages.find((message) => message._id === this.usersViews);
      },
    },
    methods: {
      ...mapActions("chat", {
        toggleState: "toggleState",
      }),
      getDate(hour) {
        return new Date(hour).toLocaleTimeString("es-CO", { hour: "numeric", minute: "numeric" });
      },
      messagesViewByUsers(msg) {
        this.usersViews = msg;
        this.toggleState("popUpViewMessagesByUsers");
      },
    },
  };
</script>

<style lang="scss">
  .chat-messages-container {
    height: 450px;
    width: 100%;
    padding: 5px 15px 0 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .own-messages-date,
    .message-date {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      margin-right: 46px;
      font-size: 12px;
      span {
        font-weight: 400;
        color: #5b5957;
        margin-right: 5px;
      }
      .view-chat {
        color: #5b5957;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .message-date {
      justify-content: flex-start;
      margin-left: 46px;
    }
    .own-chat-message,
    .chat-message {
      text-align: right;
      .message-text {
        display: inline-block;
        text-align: left;
        min-width: 80px;
        min-height: 35px;
        max-width: 240px;
        background-color: #bd0909;
        padding: 8px 18px;
        color: white;
        font-size: 14px;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        margin-right: 12px;
        margin-bottom: 5px;
        word-wrap: break-word;
      }
    }
    .chat-message {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      .message-text {
        color: #5b5957;
        background-color: #f6f6f6;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 0;
        word-wrap: break-word;
      }
      .msg-image {
        margin-right: 12px;
      }
    }

    .msg-image {
      height: 33px;
      width: 33px;
      border-radius: 50%;
      object-fit: cover;
    }
    .view-users-by-messages {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 5px;
      }
    }
  }

  .unread-msg {
    margin: 5px 0 20px 0;
    border-radius: 11px;
    text-align: center;
    color: #dddddd;
    background-color: #5b5957;
  }
</style>
