<script>
  import { mapActions, mapMutations, mapGetters } from "vuex";
  import CommentsBar from "../reactions/Comments/CommentsBar.vue";
  import { EventBus } from "@/assets/js/eventBus";

  export default {
    components: {
      Carrousel: () => import("./CompetitionCarrousel.vue"),
      Card: () => import("./Card.vue"),
      CardSkeleton: () => import("./CardSkeleton.vue"),
      ListViewers: () => import("./ListViewers.vue"),
      ModalCompetition: () => import("./ModalCompetition.vue"),
      CommentsList: () => import("./commentsList.vue"),
      CommentsBar,
      UsersListVisor: () => import("../../components/widgets/Modals/UsersListVisor.vue"),
    },
    data() {
      return {
        isLoading: true,
        isLoadingMore: false,
        page: 0,
        hasMore: false,
        showModalViewers: false,
        totalViewersContest: 0,
        selectedParticipant: null,
        objCurrentAwads: null,
        loadingCards: 12,
        comments: [],
        likers: [],
        statusLIkersView: false,
        showAnimateTitle: false,
        timer: null,
        observer: null,
        timeOutId: null,
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetition", "getRanking"]),
      competitionId() {
        return this.$route.params.id;
      },
      best() {
        return this.getRanking.slice(0, this.getCompetition.totalPodium);
      },
      participants() {
        return this.getRanking.slice(this.getCompetition.totalPodium);
      },
      positionPodium() {
        return this.getCompetition.totalPodium + 1;
      },
      userIsAdmin() {
        return this.$userData.role === "superadmin";
      },
      showComments() {
        const hasNoUsersPolicy = this.getCompetition?.policiesInteration?.users === null;
        if(hasNoUsersPolicy) {
          return true;
        }
        const isAdmin = this.userIsAdmin;
        const isUserIncluded = this.getCompetition?.policiesInteration?.users?.includes(this.$userData._id);

        return isAdmin || isUserIncluded;
      },
      commentsData() {
        if (!this.showComments) {
          return [];
        }
        return this.$store.state.comments.comment;
      },
      usersComment() {
        return this.comments.map((item) => item.user);
      },
      userId() {
        return this.$userData._id;
      },
    },
    watch: {
      competitionId() {
        this.changeCompetition();
        if (this.timeOutId) {
          clearTimeout(this.timeOutId);
        }
        this.timeOutId = setTimeout(() => {
          this.animateSubtitle();
        }, 500);
      },
      isLoading(newVal) {
        if(this.timeOutId){
          clearTimeout(this.timeOutId);
        }
        if (!newVal) {
          this.timeOutId = setTimeout(() => {
            this.animateSubtitle();
          }, 500);
        }
      },
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionById", "getRankingDb"]),
      ...mapActions(["setItemViewed", "getItemViewersTotal", "getItemViewers"]),
      ...mapActions("comments", ["getCommentsByModel"]),
      ...mapMutations("competitions", ["setRanking", "setNewRanking", "setComments"]),
      async setRankingData() {
        const { hasMore, ranking = [] } = await this.getRankingDb({ contestId: this.competitionId, query: `page=${this.page}` });
        this.hasMore = hasMore;
        this.setRanking(ranking);
      },
      async setMoreRankingData() {
        if (!this.hasMore || this.isLoading || this.isLoadingMore) {
          return;
        }

        this.isLoadingMore = true;
        this.page++;

        const { hasMore, ranking = [] } = await this.getRankingDb({ contestId: this.competitionId, query: `page=${this.page}` });

        this.hasMore = hasMore;
        this.setNewRanking(ranking);
        this.isLoadingMore = false;
      },
      async changeCompetition() {
        this.isLoading = true;
        this.page = 0;
        this.hasMore = false;

        await this.getCompetitionById({ _id: this.competitionId });
        await this.setRankingData();
        await this.getComments();
        this.setNewView(this.competitionId);
        await this.findUserInViewers();

        if (this.userIsAdmin) {
          await this.getTotalViewersContest(this.competitionId);
        }
        this.isLoading = false;
      },
      getParticipantAward(position) {
        if (this?.getCompetition.positionByRange) {
          position++;
          return this.getCompetition.awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
        } else {
          if (this.getCompetition.awards && position >= 0 && position < this.getCompetition.awards.length) {
            return this.getCompetition.awards[position];
          }
        }
      },
      async getTotalViewersContest(itemId) {
        this.totalViewersContest = await this.getItemViewersTotal(itemId);
      },
      showUsersView(mode) {
        this.showModalViewers = mode;
      },
      setModeScroll(mode = "initial") {
        if (!document) {
          return;
        }
        document.body.style.overflowY = mode;
      },
      async showModalDetails(participant = null, award = null) {
        this.selectedParticipant = participant;
        this.objCurrentAwads = award;

        if (!participant) {
          return;
        }
        if (this.showComments) {
          await this.getCommentsByModel(participant._id);
        }

        this.setNewView(participant._id);
      },
      async setNewView(itemId) {
        if (!["active", "finished"].includes(this.getCompetition?.status)) {
          return;
        }
        await this.setItemViewed(itemId);
      },
      async getComments() {
        try {
          const { data } = await this.$axios.get("/appcomments/comments", {
            params: {
              item: this.competitionId,
            },
          });
          this.comments = data.comments;
          this.setComments(data.comments);
        } catch (error) {
          console.log(error);
        }
      },
      changeStatusVisor() {
        this.statusLIkersView = !this.statusLIkersView;
      },
      listOfLikes(event) {
        this.changeStatusVisor();
        this.likers = event;
      },
      async findUserInViewers() {
        if (!this.competitionId) {
          return null;
        }
        const { viewers } = await this.getItemViewers({ item: this.competitionId, userId: this.userId });
        if (!viewers.length || !this.getCompetition) {
          return;
        }

        const updatedAt = new Date(this.getCompetition.updatedAt);
        const countViews = viewers[0].dates.filter((date) => {
          const viewDate = new Date(date);
          return viewDate >= updatedAt;
        }).length;
        this.showAnimateTitle = this.getCompetition.maxShows >= countViews;
      },
      animateSubtitle() {
        const titleElement = document.querySelector(".commentsBar__titleAnimate");
        const iconElement = document.querySelector(".commentsBar__icon");
        if (titleElement && iconElement) {
          titleElement.classList.add("commentsBar__titleHover");
          iconElement.classList.add("commentsBar__iconHover");
          this.timer = setTimeout(() => {
            titleElement.classList.remove("commentsBar__titleHover");
            iconElement.classList.remove("commentsBar__iconHover");
            clearTimeout(this.timer);
          }, 700);
        }
      },
      addCommentToParticipant(participantId, newComment) {
        const participant = this.participants.find((participant) => participant._id === participantId) || this.best.find((participant) => participant._id === participantId);
        if (!participant) return;
        participant.comments.push(newComment);
      },
    },
    created() {
      EventBus.$on("addComment", this.addCommentToParticipant);
    },
    beforeMount() {
      this.changeCompetition();
    },
    beforeUnmount() {
      if (!this.observer) return;
      this.observer.disconnect();
    },
    beforeDestroy() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      EventBus.$off("addComment", this.addCommentToParticipant);
    },
  };
</script>

<template>
  <div class="rankingList" ref="rankingList">
    <template v-if="!isLoading">
      <CommentsBar
        :contest="getCompetition"
        :title="getCompetition.description"
        :totalViewers="totalViewersContest"
        :showComments="showComments"
        @openVisor="() => showUsersView(true)"
        :scrollToBottom="true"
        :comments="usersComment"
        :showAnimateTitle="showAnimateTitle"
        @listOfLikes="listOfLikes" />
      <Carrousel
        :best="best"
        :awards="getCompetition.awards"
        :subtitle="getCompetition.description"
        :key="0"
        :modal="true"
        :statusRange="getCompetition.positionByRange"
        :canShowDetails="getCompetition.showRankingDetails"
        @showModalDetails="showModalDetails"
        v-if="best.length" />
      <div class="rankingList__cardContainer">
        <Card
          v-for="(participant, idx) in participants"
          :key="participant.user._id"
          :idxCard="idx"
          :participant="participant"
          :award="getParticipantAward(idx + 3)"
          :position="idx + positionPodium"
          :canShowDetails="getCompetition.showRankingDetails"
          @showModalDetails="showModalDetails" />
      </div>
      <button class="click-on-bottom" v-if="hasMore && !isLoading && !isLoadingMore" @click="setMoreRankingData">Ver más</button>
    </template>
    <div class="rankingList__cardContainer" v-else>
      <CardSkeleton v-for="n in loadingCards" :key="n" />
    </div>
    <div class="rankingList__commentsContainer">
      <CommentsList :comments="comments" :contest="getCompetition" :totalViewers="totalViewersContest" @listOfLikes="listOfLikes" @openVisor="() => showUsersView(true)" :showComments="showComments" />
    </div>
    <ListViewers :competitionId="competitionId" :close="() => showUsersView(false)" v-if="showModalViewers" />
    <ModalCompetition v-if="selectedParticipant" :participant="selectedParticipant" :award="objCurrentAwads" :comments="commentsData" :showComments="showComments" @close="showModalDetails()" />
    <UsersListVisor v-if="statusLIkersView && likers.likers" :users="likers.likers" :closeModal="changeStatusVisor" :detail="'Los usuarios que dieron like:'" />
  </div>
</template>

<style lang="scss" scoped>
  .rankingList {
    @include Flex(column);
    .spinner {
      width: 100px;
      height: 100px;
    }
    position: relative;
    width: 100%;
    &__cardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden;
      gap: 10px;
      padding: 0 0.2rem;
      width: 100%;
      margin: 1rem 0;
    }
    &__views {
      position: absolute;
      top: 0;
      right: 0;
      @include Flex(row);
      width: fit-content;
      gap: 10px;
      padding: 5px 10px;
      font-weight: 600;
      color: $chicago;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    @media (min-width: 375px) {
      &__commentsContainer {
        width: 100%;
        height: 100%;
        margin-bottom: 90px;
      }
    }

    @media (min-width: 620px) {
      &__cardContainer {
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      }
      &__commentsContainer {
        margin-bottom: 30px;
      }
    }
    @media (min-width: 1024px) {
      &__views {
        top: $space-24;
      }
    }
  }
</style>
