<template>
  <Modal class="userMagnamentState" :showMask="true" @onCancel="closeModal">
    <template v-slot:header>
      <div class="userMagnamentState__header">
        <h2 class="userMagnamentState__title">Estado de usuario</h2>
        <button class="userMagnamentState__close" @click="closeModal">
          <iconic class="userMagnamentState__icon" name="close" />
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="userMagnamentState__body">
        <h3 class="userMagnamentState__subtitle">Inactivacion</h3>
        <div class="userMagnamentState__inputsContainer">
          <div class="userMagnamentState__inputsContainer userMagnamentState__inputsContainer--m" v-for="option in optionsDisabled" :key="option.key">
            <input class="mycheck" type="radio" :id="option.key" :value="option.key" v-model="dataToSet.reason" />
            <label class="userMagnamentState__label" :for="option.key">{{ option.name }}</label>
          </div>
        </div>
        <div class="userMagnamentState__tagsContainer" v-if="showTagsDisabled">
          <div :class="['userMagnamentState__tag', tagsSelected.includes(tag._id) && 'userMagnamentState__tag--selected']" v-for="tag in tags" :key="tag._id" @click="setTagSelected(tag._id)">
            {{ tag.name }}
          </div>
          <div class="userMagnamentState__tag" contenteditable="true" @blur="createTag" @keydown.enter="createTag" @click="textTag = ''">{{ textTag }}</div>
        </div>
        <template>
          <h3 class="userMagnamentState__subtitle">Licencia</h3>
          <div class="userMagnamentState__inputsContainer">
            <div class="userMagnamentState__inputsContainer userMagnamentState__inputsContainer--m" v-for="option in optionsLicence" :key="option.key">
              <input class="mycheck" type="radio" :id="option.key" :value="option.key" v-model="dataToSet.reason" @click="$refs.myDatePicker.togglePicker()" />
              <label class="userMagnamentState__label" :for="option.key">{{ option.name }}</label>
            </div>
          </div>
        </template>
        <p class="userMagnamentState__inputsMsg" v-if="!dataToSet.reason">Seleccione el motivo</p>
        <v-md-date-range-picker ref="myDatePicker" @change="handleChange" opens="center"></v-md-date-range-picker>
        <div class="userMagnamentState__inputsContainer--date" v-if="showCalendar" @click="$refs.myDatePicker.togglePicker()">
          <iconic name="calendar"></iconic>
          <span class="date" v-if="!license.dateFrom">Fecha</span>
          <span class="date" v-else>
            {{ $global.dateLocaleFormat(license.dateFrom, options) }}
            - {{ $global.dateLocaleFormat(license.dateTo, options) }}
          </span>
        </div>
        <template v-if="['endContract', 'waiver', 'incapacidad'].includes(dataToSet.reason)">
          <div class="userMagnamentState__inputsFiles">
            <label class="userMagnamentState__label" for="suport">Soportes *</label>
            <input
              class="userMagnamentState__files ginput"
              type="file"
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              @change="setFile"
            />
          </div>
          <p class="userMagnamentState__inputsMsg">Seleccione un archivo</p>
        </template>
        <div class="userMagnamentState__inputsFiles" v-if="['endContract', 'waiver', 'deserter'].includes(dataToSet.reason)">
          <label class="userMagnamentState__label" for="suport">Comentario *</label>
          <textarea class="userMagnamentState__inputsContainer--textarea" cols="45" rows="2" placeholder="Descripcion de inactivacion *" v-model="dataToSet.descriptionDisabled"></textarea>
        </div>
        <div class="userMagnamentState__inputsFiles" v-if="dataToSet.reason === 'licencia'">
          <label class="userMagnamentState__label" for="suport">Descripcion *</label>
          <textarea class="userMagnamentState__inputsContainer--textarea" cols="45" rows="2" placeholder="Descripcion de licencia *" @input="setDescription"></textarea>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="userMagnamentState__fotter">
        <button class="userMagnamentState__buttonAction userMagnamentState__buttonAction--cancel" @click="closeModal">Cancelar</button>
        <button class="userMagnamentState__buttonAction" @click="submitModal">Aceptar</button>
      </div>
    </template>
  </Modal>
</template>

<script>
  export default {
    props: ["closeModal", "setUpdateUser"],
    components: {
      Modal: () => import("@/components/modals/Modal.vue"),
    },
    data() {
      return {
        showCalendar: false,
        typeDisabled: "",
        dataToSet: {
          reason: "",
          descriptionDisabled: "",
          isEnable: false,
        },
        license: {},
        options: {
          month: "short",
          day: "numeric",
          timeZone: "America/Bogota",
        },
        textTag: "otro",
        tags: [],
        tagsSelected: [],
      };
    },
    computed: {
      user() {
        return this.$store.state.user.userSelected;
      },
      showTagsDisabled() {
        return ["endContract", "waiver", "deserter"].includes(this.dataToSet.reason);
      },
      optionsDisabled() {
        const options = [
          { key: "endContract", name: this.user.role === "model" ? "Finalización alianza" : "Termina contrato" },
          { key: "waiver", name: "Renuncia" },
        ];
        if (this.user.role === "model") {
          options.unshift({ key: "deserter", name: "Deserta" });
        }

        return options;
      },
      optionsLicence() {
        return [
          { key: "vacaciones", name: "Vacaciones" },
          { key: "incapacidad", name: "Incapacidad" },
          { key: "licencia", name: "Otro" },
        ];
      },
    },
    watch: {
      async "dataToSet.reason"(val) {
        if (!["vacaciones", "incapacidad", "licencia"].includes(val)) {
          delete this.license.dateFrom;
          delete this.license.dateTo;
          this.showCalendar = false;
        }
        if ("licencia" !== val) {
          delete this.license.descriptionLicense;
        }

        if (!["endContract", "waiver"].includes(val)) {
          delete this.dataToSet.suportFile;
        }
        if (["endContract", "waiver", "deserter"].includes(val)) {
          this.tags = await this.getTags(val);
          this.tagsSelected.length = 0;
        }
      },
    },
    methods: {
      verifySwichCase(reason) {
        if (!reason) {
          return false;
        }
        if (["endContract", "waiver", "deserter"].includes(reason) && !this.dataToSet?.descriptionDisabled) {
          return false;
        }
        if (["vacaciones", "incapacidad", "licencia"].includes(reason) && (!this.license?.dateFrom || !this.license?.dateTo)) {
          return false;
        }
        if (reason === "licencia" && !this.license?.descriptionLicense) {
          return false;
        }
        return true;
      },
      submitModal() {
        if (!this.verifySwichCase(this.dataToSet.reason)) {
          return;
        }
        if (["vacaciones", "incapacidad", "licencia"].includes(this.dataToSet.reason)) {
          this.dataToSet.license = this.license;
        }
        if (this.tagsSelected.length) {
          this.dataToSet.tagsDisabled = [...new Set(this.tagsSelected)];
        }

        this.setUpdateUser(this.dataToSet, false);
        this.closeModal();
      },
      setFile(event) {
        this.dataToSet.suportFile = event.target?.files?.[0] || "";
      },
      setDescription(event) {
        const { value } = event.target;
        if (!value) {
          delete this.license.descriptionLicense;
          return;
        }
        this.license.descriptionLicense = value;
      },
      handleChange(values) {
        this.license.dateFrom = values[0]._d;
        this.license.dateTo = values[1]._d;
        this.showCalendar = true;
      },
      async createTag(e) {
        let name = e.target?.innerText?.toLowerCase();
        name = name.trim();

        this.textTag = "otro";
        setTimeout(() => {
          e.target.blur();
        }, 0);

        if (!name || name === "otro") {
          return;
        }

        const existTag = this.tags.find((tag) => tag.name === name);

        if (existTag) {
          this.tagsSelected.push(existTag._id);
          return;
        }

        const { data } = await this.$axios.post(`/tags`, { name, categories: [this.dataToSet.reason] });
        const { success, message, tagCreated } = data;

        if (success) {
          this.tags.push(tagCreated);
          this.tagsSelected.push(tagCreated._id);
        }
      },
      setTagSelected(valTagId) {
        const tagIdx = this.tagsSelected.findIndex((tagId) => tagId === valTagId);

        if (tagIdx !== -1) {
          this.tagsSelected.splice(tagIdx, 1);
        } else {
          this.tagsSelected.push(valTagId);
        }
      },
      async getTags(categories) {
        const { data } = await this.$axios.get(`/tags?categories=${categories}`);
        const { tags = [] } = data;

        return tags;
      },
    },
  };
</script>

<style lang="scss">
  .userMagnamentState {
    .modal-g__container {
      width: 100%;
      max-width: 500px;
      padding: 0;
      gap: $mpadding;
    }
    &__tagsContainer {
      @include Flex(row, flex-start);
      flex-wrap: wrap;
      width: 100%;
      gap: $mpadding;
    }
    &__tag {
      @include Flex(row);
      width: max-content;
      gap: 5px;
      padding: 2px 10px;
      font-size: 15px;
      text-transform: capitalize;
      border-radius: 14px;
      color: black;
      background: #f1f1f1;
      outline: none;
      cursor: pointer;
      user-select: none;
      transition: 0.1s ease-in-out;
      &--selected {
        color: $primary-color;
        background: rgba(242, 189, 189, 0.3);
      }
      &:focus {
        border: 1px solid $chicago;
      }
    }

    &__header {
      @include Flex(row, space-between);
      width: 100%;
      padding: $mpadding;
      border-bottom: 1px solid #c4c4c4;
    }
    &__body {
      @include Flex(column, flex-start, center);
      width: 100%;
      padding: 0 $mpadding;
      gap: 8px;
    }
    &__title {
      font-weight: lighter;
    }
    &__subtitle {
      width: 100%;
      font-weight: lighter;
    }
    &__close {
      @include Flex(row);
      height: 35px;
      width: 35px;
      color: $primary-color;
      border: none;
      border-radius: 50%;
      background: none;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        background: #bd09092e;
      }
    }
    &__fotter {
      @include Flex(row);
      gap: $mpadding;
      padding-bottom: $mpadding;
    }
    &__buttonAction {
      width: 120px;
      height: 40px;
      font-weight: 600;
      color: white;
      background-color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 4px;
      &--cancel {
        color: $primary-color;
        background-color: white;
      }
    }
    &__inputsContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: $mpadding;
      &--m {
        @include Flex(row, flex-start);
        width: 100%;
      }
      &--date {
        cursor: pointer;
        .date {
          margin: 8px;
          position: relative;
          top: 2px;
        }
      }
      &--textarea {
        width: 100%;
        height: 2.75em;
        padding: 0.3em;
        text-align: left;
        resize: none;
        border-radius: 6px;
        outline: 0 none;
      }
    }
    &__label {
      color: $chicago;
      font-weight: bold;
      width: calc(100% - 35px);
    }
    .mycheck {
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
    &__inputsMsg {
      font-size: 12px;
    }
    &__inputsFiles {
      width: 100%;
    }
    &__files {
      width: 100%;
      height: 36px;
      padding: 5px;
      font-size: 0.9em;
      background: $chicago;
      color: $white;
      border-radius: 5px;
      cursor: pointer;
      &::-webkit-file-upload-button {
        display: none;
      }
    }
    /* Native calendar classes used to position the calendar */
    .mdrp__activator {
      display: none;
    }
    .mdrp__panel.dropdown-menu[data-v-7863e830] {
      position: relative;
      margin-top: -30px;
      left: 0px;
      padding: $mpadding;
      margin-bottom: 45px;
    }
    .mdrp__panel .calendar-table.right[data-v-7863e830] {
      display: none;
    }
    table.calendar-table td.calendar-cell.active .calendar-cell__content[data-v-89ba891e],
    table.calendar-table td.calendar-cell.active:hover .calendar-cell__content[data-v-89ba891e] {
      background-color: $lipstick;
    }
    .ranges ul[data-v-4e185066] .active {
      color: $lipstick;
    }
  }
</style>
