<template>
  <section class="helpCreate fade-in" v-bind="initSocketEvents">
    <div class="helpCreate__card" v-if="!show">
      <div v-for="(types, index) in typesrequirements" :key="index" gcard>
        <h5>
          {{ validArea(index) || "Otro" }}
        </h5>
        <div :class="['helpCreate__cards helpCreate__cards2 gcard', types[idx]['isEnable'] ? '' : 'gray']" v-for="(ind, idx) in types.length" :key="idx">
          <div class="helpCreate__cards2" @click="(show = true), (typerequired = types[idx]._id)">
            <h5>
              <iconic name="messageAlert"></iconic>
            </h5>
            <p class="p">
              {{ types[idx] ? types[idx].name : "" }}
            </p>
          </div>
          <span class="helpCreate__cards2-icons">
            <button class="helpCreate__cards2-edit helpCreate__cards2-edit-one" v-b-tooltip.hover.top="'Editar'" @click="openModal(types[idx])" v-if="$userData.role === 'superadmin'">
              <iconic name="edit"></iconic>
            </button>
            <button class="helpCreate__cards2-edit e" @click="sendData('delete', types[idx], types[idx])" v-if="false">
              <iconic name="trash_solid"></iconic>
            </button>
            <i class="fa fa-question-circle helpCreate__dude" v-b-tooltip.hover.top="types[idx].description" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>
    <div v-if="show" class="helpCreate__container">
      <div class="gcard">
        <h6 class="helpCreate__title">Creacion de ticket</h6>
        <div class="helpCreate__two">
          <div class="helpCreate__two-2">
            <label for="typerequired" class="b">Tipo de requerimiento *</label>
            <select class="ginput helpCreate__selects" v-model="typerequired">
              <option value="">Seleccione un motivo</option>
              <option @click="otro = area.name" :value="requirement._id" v-for="(requirement, idx) in typesrequirementsSelect" :key="idx">
                {{ requirement.name }}
              </option>
            </select>
            <label for="typerequired" class="b">Titulo de requerimiento *</label>
            <input type="text" class="helpCreate__selects" v-model="title" ginput placeholder="Escribe el titulo del caso" />
            <template v-if="casesRequireLocation.includes(typerequired)">
              <div class="helpCreate__two-2">
                <label for="typerequired" class="b">Ciudad *</label>
                <select class="ginput helpCreate__selects" v-model="city" @change="(office = ''), (room = ''), (location = '')">
                  <option value="" selected>Ciudades</option>
                  <option v-for="(citie, idx) in cities" :key="idx" :value="citie">{{ citie }}</option>
                </select>
              </div>
              <div class="helpCreate__two-2">
                <label for="typerequired" class="b">Sede *</label>
                <select class="ginput helpCreate__selects" v-model="office" @change="(room = ''), (location = '')">
                  <option value="" selected>Sedes</option>
                  <option v-for="office in offices" :key="office._id" :value="office.officeName">{{ office.officeName }}</option>
                </select>
              </div>
              <div class="helpCreate__two-2">
                <label for="typerequired" class="b">Lugar *</label>
                <select class="ginput helpCreate__selects" v-model="roomOrLocation">
                  <option value="" selected>Seleccione un lugar</option>
                  <option value="room" selected>Habitación</option>
                  <option value="location" selected>Locación</option>
                </select>
              </div>
              <div class="helpCreate__two-2" v-if="roomOrLocation === 'room'">
                <label for="typerequired" class="b">Habitación</label>
                <select class="ginput helpCreate__selects" v-model="room" @change="location = ''">
                  <option value="" selected>Seleccione una habitación</option>
                  <option :value="room.roomName" v-for="(room, idx) in rooms" :key="idx">
                    {{ room.roomName }}
                  </option>
                </select>
              </div>
              <div class="helpCreate__two-2" v-if="roomOrLocation === 'location'">
                <label for="typerequired" class="b">Locación</label>
                <select class="ginput helpCreate__selects" v-model="location" @change="room = ''">
                  <option value="" selected>Seleccione una habitación</option>
                  <option :value="location.name" v-for="(location, idx) in locationsOffice" :key="idx">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </template>
          </div>
        </div>
        <div class="helpCreate__other" v-if="validOther">
          <div class="helpCreate__two-1">
            <label for="area" class="b">Area</label>
            <select id="area" class="ginput helpCreate__selects" v-model="area" @change="otro = area.name">
              <option value="">Seleccione un area *</option>
              <option :value="area._id" v-for="(area, index) in areas" :key="index">{{ area.name }}</option>
            </select>
          </div>
        </div>
        <div class="">
          <label for="files" class="b">Adjuntos</label>
          <input class="helpCreate__files ginput" required @change="setFile" :placeholder="'sube un vídeo, imagen, pdf o documento'" type="file" id="files" multiple />
        </div>
        <div class="">
          <label for="description" class="b">Descripción *</label>
          <textarea class="helpCreate__description helpCreate__selects-area" id="description" placeholder="Cuentanos de que se trata..." v-model="description"></textarea>
        </div>
      </div>
      <center>
        <button class="helpCreate__button helpCreate__button-gray ginput" :disabled="isLoading" @click="(show = false), cleanInputs()">Cancelar</button>
        <button class="helpCreate__button ginput m" :disabled="isLoading" @click="createCases">Crear</button>
      </center>
    </div>
    <modalRequirements :closeModal="closeModal" :requirements="dataToModal" :sendData="sendData" :areas="areas"></modalRequirements>
  </section>
</template>
<script>
  import mixinCrud from "./mixinCrud";
  import { socket } from "@/socket";
  import modalRequirements from "./modalRequirements.vue";
  export default {
    components: { modalRequirements },
    name: "createcase",
    mixins: [mixinCrud],
    data() {
      return {
        title: "",
        typesrequirements: [],
        typesrequirementsSelect: [],
        areas: [],
        locationsOffice: [],
        rooms: [],
        area: "",
        other: "",
        city: "",
        office: "",
        location: "",
        room: "",
        roomOrLocation: "",
        typerequired: "",
        files: [],
        description: "",
        isLoading: false,
        show: false,
        otro: "",
        dataToModal: "",
        casesRequireLocation: ["626ab93453056ef76686f164", "62754d2215ad0639c2b898dd", "62754ede15ad0639c2b898de", "627558a415ad0639c2b898eb", "626abfff1bccb73b9432b0aa"],
      };
    },
    computed: {
      cities() {
        return this.$cities.filter((citie) => {
          if (this.$userData.role === "model") return citie === this.$userData.city;
          return true;
        });
      },
      offices() {
        if (!this.city) return [];
        return this.$store.getters["offices/getData"]
          .filter((x) => x?.cityName === this.city)
          .filter((office) => {
            if (this.$userData.role === "model") return office.officeName === this.$userData.office;
            return true;
          });
      },
      validOther() {
        let other = false;
        this.typesrequirementsSelect.forEach((requirement) => {
          if (requirement.name.toUpperCase() === "OTRO" && requirement._id === this.typerequired) other = true;
        });
        return other;
      },
      initSocketEvents() {
        if (this.$socketState) {
          socket.on("progressCase", this.onloadPresentage);
        }
      },
    },
    watch: {
      async office(val) {
        if (!val) {
          this.room = "";
          this.rooms = [];
          return;
        }
        await this.getRooms(`&office=${val}`);
      },
    },
    methods: {
      onloadPresentage(v) {
        this.$alerts.onChangeProgress(v);
      },
      setFile({ srcElement }) {
        this.file = srcElement.files;
      },
      openModal(data) {
        this.dataToModal = data;
        this.setFilter({ srcElement: { name: "showModal", value: true } });
      },
      closeModal() {
        this.dataToModal = "";
      },
      async sendData(action, info, actual) {
        this.isLoading = true;
        let response;
        if (action === "create") {
          response = await this.createRequirement(info);
          Object.keys(this.typesrequirements).forEach((key) => {
            if (key === response.data.data.idArea) this.typesrequirements[key].push(response.data.data);
          });
        } else if (action === "update") {
          response = await this.updateRequirement(info);
          Object.keys(this.typesrequirements).forEach((key) => {
            if (key === response.data.data.idArea) this.typesrequirements[key].splice(this.typesrequirements[key].indexOf(actual), 1, response.data.data);
          });
        } else if (action === "delete") {
          const { isConfirmed } = await this.$alerts.dataSendConfirm({
            title: "¿Esta seguro que decea eliminar este requerimiento?",
            message: `Solo se podra eliminar si no hay casos asociados a este requerimiento`,
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
          });
          if (!isConfirmed) return;
          response = await this.deleteRequirement(info._id);
        }
        this.nweSuccess({ message: action === "create" ? "Se ha creado correctamente" : "Se ha actualizado correctamente" });
        this.isLoading = false;
      },
      validInputs() {
        const validOther = this.validOther;
        if (validOther) if (!this.area) return false;
        if (!this.typerequired || !this.description || !this.title) return false;
        if (this.casesRequireLocation.includes(this.cleanInputstyperequired)) {
          if (!this.city || !this.office || !this.roomOrLocation) return false;
          if (!this.room && !this.location) return false;
        }
        if (this.typerequired === "62702e0e50caf8169a63d37e" && !(this.file || []).length) return false;
        return true;
      },
      returnAreaDinamic() {
        let [areas, area] = [this.requiredComplet(), ""];
        try {
          area = areas.directTo[this.$userData.role]._id;
        } catch (error) {
          area = areas.idArea;
        }
        return area;
      },
      async createCases() {
        if (!this.validInputs()) {
          this.$alerts.dataSendError({
            title: "Verifique nuevamente",
            message: "Debe llenar todos los campos Requeridos.",
          });
          return;
        }
        this.isLoading = true;
        this.$alerts.dataSending("Creando Caso...");
        const data = new FormData();
        if (!this.area) this.area = this.returnAreaDinamic();
        data.append("title", this.title);
        data.append("area", this.area);
        data.append("priority", "low");
        data.append("typeRequirement", this.typerequired);
        data.append("description", this.description);
        data.append("city", this.city);
        data.append("office", this.office);
        data.append("location", this.location);
        data.append("room", this.room);
        let sizesFiles = 0;
        if (this.file) {
          for (let i = 0; i < this.file.length; i++) {
            data.append(`file${i}`, this.file[i]);
            sizesFiles += this.file[i].size;
          }
        }
        if (sizesFiles > 1000000000) {
          this.isLoading = false;
          this.$alerts.dataSendError({ message: "El tamaño de los archivos no puede ser mayor a 1GB" });
          this.file = [];
          this.textFiles = "";
          return;
        }
        const validCase = await this.createCase(data);
        this.isLoading = false;
        if (!!validCase.data.error) {
          this.$alerts.dataSendError({
            title: "Ocurrio un error en la creación",
            message: "Intenta nuevamente",
          });
          return;
        }
        this.show = false;
        this.cleanInputs();
        setTimeout(() => {
          this.$alerts.closeSwal();
          if (this.$route.name !== "helpdesk" && this.$route.name !== "resume") this.$router.push("/helpdesk/resume");
          this.$alerts.dataSendSuccess({ message: "Se ha creado el caso Correctamente" });
        }, 500);
      },
      cleanInputs() {
        this.area = "";
        this.typerequired = "";
        this.files = [];
        this.description = "";
        this.title = "";
      },
      requiredComplet() {
        let other;
        this.typesrequirementsSelect.forEach((requirement) => {
          if (requirement._id === this.typerequired) other = requirement;
        });
        return other;
      },
      async getRooms(query) {
        const { data } = await this.$axios.get("/rooms?all=true" + query);
        this.rooms = data.rooms;
      },
    },
    async beforeMount() {
      await this.getInfoCases("area", "areas");
      await this.getInfoCases("requirement", "typesrequirements");
      await this.getInfoCases("locations", "locationsOffice");
      this.typesrequirementsSelect = this.typesrequirements;
      this.typesrequirements = this.typesrequirements.reduce((acc, cur) => {
        const id = cur.idArea;
        if (!acc[id]) acc[id] = [];
        acc[id].push(cur);
        return acc;
      }, {});
      Object.keys(this.typesrequirements).forEach((key) => {
        this.typesrequirements[key] = this.typesrequirements[key].sort((a, b) => {
          if (a.index < b.index) return -1;
          if (a.index > b.index) return 1;
          return 0;
        });
      });
      const { city, room, office } = this.$userData;
      this.city = city || "";
      this.office = office || "";
      this.room = room?.trim() || "";
    },
  };
</script>

<style lang="scss">
  .helpCreate {
    .gray {
      background-color: #c5c5c5;
    }
    animation: marginChange 1s ease-in-out;
    @keyframes marginChange {
      0% {
        margin-top: -70px;
      }
      100% {
        margin-top: 0px;
      }
    }

    .ginput {
      text-transform: capitalize;
    }
    .m {
      margin-left: 15px;
      background-color: $primary-color;
    }
    &__dude {
      color: $chicago;
    }
    &__container {
      margin-top: 15px;
    }
    &__card {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      margin-top: 20px;
      grid-gap: 20px;
      text-transform: capitalize;
    }
    &__card2 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      white-space: nowrap;
      gap: 19px;
    }
    &__contentLocation {
      @include Flex(row, space-between);
    }
    &__selects {
      outline: none;
      border: solid 1px #eceaea;
      box-shadow: 0px 0.5px 2px 0px #00000069;
      &-area {
        outline: none;
        box-shadow: none;
      }
    }

    &__cards {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      height: 67px;
      background-color: #f5f5f5;
      border-radius: 5px;
      border-top: 6px solid $primary-color;
      width: 100%;
      margin: 10px 0;
      .p {
        margin-left: 7px;
        font-weight: 600;
        width: 180px;
      }
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: white;
        color: $primary-color;
        transition: all 0.3s ease-in-out;
        transform: scale(1.05);
      }
    }
    &__cards2 {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      &-edit {
        border: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        &-one {
          padding-top: 5px;
        }

        &:hover {
          background-color: transparent;
          outline: none;
        }
      }
      &-icons {
        width: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: transparent;
          outline: none;
        }
      }
    }
    &__two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin: 3px 0;
      &-1 {
        width: 100%;
      }
      &-2 {
        width: 100%;
      }
    }
    &__description {
      width: 100%;
      border-radius: 6px;
      border: solid 1px #d3d2d2;
      padding: 6px 8px;
      text-transform: none;
      height: 100px;
    }
    &__title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 15px;
    }
    &__files {
      width: 100%;
      min-height: 36px;
      white-space: pre-line;
      max-height: 50px;
      text-decoration: elipsis;
      background: $chicago;
      color: $white;
      font-size: 0.9em;
      border-radius: 5px;
      align-items: center;
      padding-top: 7px;
      &--transparent {
        color: transparent;
      }
      &::-webkit-file-upload-button {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
      &::before {
        content: attr(placeholder);
        margin-right: 10px;
        margin-left: 10px;
        font-size: 1em;
        color: $white;
      }
    }
    &__button {
      width: 120px;
      margin-top: 15px;
      background-color: $primary-color;
      border: none;
      padding: 5px;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      &-gray {
        background-color: transparent;
        border: solid $primary-color 1px;
        font-weight: 600;
        color: $primary-color;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    &__two {
      display: flex;
    }
  }
  .b {
    margin-top: 10px;
    font-weight: 600;
  }
</style>
