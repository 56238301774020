<script>
  import emojis from "../../widgets/Emojis.vue";

  export default {
    props: ["className", "position", "placeholder", "isResponse"],
    data() {
      return {
        showEmojis: false,
        comment: "",
      };
    },
    components: {
      emojis,
    },
    computed: {
      isTyping() {
        return this.comment.length > 0;
      },
    },
    methods: {
      handleClickOutside(event) {
        const emojisModal = this.$refs.emojisModal?.$el;
        if (!emojisModal?.contains(event.target) && this.showEmojis) {
          this.emojisState();
        }
      },
      emojisState() {
        this.showEmojis = !this.showEmojis;
        const ranking = document.querySelector(".competitionCarousel");
        if (ranking && this.showEmojis) {
          ranking.style.zIndex = "-1";
        } else if (ranking) {
          ranking.style.zIndex = "unset";
        }
      },
      sendComment() {
        if (!this.comment?.trim()?.length) {
          return;
        }

        this.$emit("textInput", this.comment);
        this.comment = "";
      },
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
      this.emojisState();
    },
  };
</script>

<template>
  <div class="InputComment">
    <input :class="`InputComment__input ${className}`" type="text" :placeholder="placeholder || '¿Qué te parece este concurso?'" ref="inputField" v-model="comment" @keyup.enter="sendComment" />
    <div class="InputComment__icons">
      <div class="InputComment__xIcon" @click.stop="$emit('resetResponse')" v-if="isResponse">
        <iconic name="close" class="InputComment__xIcon" />
      </div>
      <div @click.stop="emojisState" class="InputComment__emojis">
        <iconic name="smile" class="commentsBar__smile" />
      </div>
      <Emojis v-show="showEmojis" :target="$refs.inputField" ref="emojisModal" :style="position" />
      <div class="InputComment__plane" v-if="isTyping" @click="sendComment">
        <iconic name="paperPlane" class="InputComment__paperPlane" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .InputComment {
    @include Flex(row, space-between);
    padding: $space-0 8px;
    width: 100%;
    height: 43px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.21);
    border-radius: 50px;
    position: relative;
    background: rgba(243, 243, 243, 0.79);
    &__input {
      width: 100%;
      display: flex;
      align-items: center;
      border: none;
      font-size: 13px;
      background: rgba(243, 243, 243, 0.79);
    }
    &__icons {
      @include Flex(row, center, center);
      gap: $space-16;
      width: fit-content;
    }
    &__xIcon {
      margin-top: $space-4;
      cursor: pointer;
    }
    &__plane {
      @include Flex(column, center, center);
      width: 30px;
      height: 30px;
      border-radius: 36.249px;
      background: $primary-color;
      cursor: pointer;
    }

    &__paperPlane {
      color: #fff;
    }

    &__emojis {
      display: flex;
      cursor: pointer;
    }
    .emojis {
      max-width: 520px;
      position: absolute;
      top: 50px;
      right: 0;
      bottom: 0px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      padding: 20px;
      height: 300px;
      z-index: 6;
      overflow: auto;
      backdrop-filter: blur(2px);
      .emoji {
        display: flex;
        height: fit-content;
        span {
          display: flex;
        }
      }
    }
  }
</style>
